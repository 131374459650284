import axios from 'axios'
import { toast } from 'sonner'

export const axiosApi = axios.create({
    baseURL: process.env.API_BACKEND,
})

axiosApi.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
        return config
    }
)

axiosApi.interceptors.response.use(
    config => {
        return config
    },
    async error => {
        console.log(error);
        if(error.code !== "ERR_CANCELED") {
            toast.error(error.response.data.detail ? error.response.data.detail : error.message);
        }
        const originalRequest = { ...error.config }
        originalRequest._isRetry = true

        if (
            error.response.status === 401 &&
            error.config &&
            !error.config._isRetry
        ) {
            try {
                const refreshToken = localStorage.getItem("refresh");

                const resp = await axiosApi.post(`${process.env.API_OAUTH}/v1/auth/refresh_token`, {
                    refresh_token: refreshToken
                });

                localStorage.setItem('token', resp.data.access);
                localStorage.setItem('refresh', resp.data.refresh);
                
                return axiosApi.request(originalRequest);
            } catch (error) {
                handleLogout();
            }
        }
        throw error
    },
)

const handleLogout = () => {
    if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = "/auth/sign-in";
    }
};