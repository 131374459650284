export const PaymentEndpoint = {
    GetPayments: `${process.env.API_BACKEND}/v1/payments/payment/`,
    CreateCashPayment: `${process.env.API_BACKEND}/v1/payments/payment/cash`,
    CreateCertificatePayment: `${process.env.API_BACKEND}/v1/payments/payment/certificate`,
    CreateOnlinePayment: `${process.env.API_BACKEND}/v1/payments/payment/online`,
    CreateAccountPayment: `${process.env.API_BACKEND}/v1/payments/payment/account`,
    ConfirmOnlinePayment: `${process.env.API_BACKEND}/v1/payments/payment/account/confirm/`,
    GetPaymentsByOrderId: `${process.env.API_BACKEND}/v1/payments/payment/order/`,
    GetPaymentMethods: `${process.env.API_BACKEND}/v1/payments/payment/methods`,
    RefundPayment: (order_id: number) => `${process.env.API_BACKEND}/v1/payments/payment/order/${order_id}/refund`,
}
