import React, { FC } from 'react'
import { Nomenclature } from 'entities/orders/model'
import { NomenclatureOldPriceLabel } from 'entities/bookings/ui/styles'
import { getNomenclatureDiscount } from 'entities/nomenclatures/model/utils'

interface NomenclatureListPreviewItemProps {
    nomenclature: Nomenclature
    range: number
}

export const NomenclatureListPreviewItem: FC<
    NomenclatureListPreviewItemProps
> = ({ nomenclature, range }) => {
    const { isDiscount, absolutDiscountTotal } =
        getNomenclatureDiscount(nomenclature)
    const priceLabel = nomenclature?.price
        ? nomenclature?.price - absolutDiscountTotal
        : nomenclature?.price

    return (
        <li>
            {nomenclature.name}{' '}
            {nomenclature.price && isDiscount ? (
                <>
                    <NomenclatureOldPriceLabel>
                        ₽{nomenclature.price}
                    </NomenclatureOldPriceLabel>{' '}
                    ₽{priceLabel}
                </>
            ) : (
                <>₽{nomenclature.price}</>
            )}{' '}
            ({nomenclature.count}шт) {nomenclature.type === 0 && `x ${range}ч`}
        </li>
    )
}
