import { axiosApi } from "shared/api/axios";
import { ResponseAreas } from "../types";

export const getAreas = async (): Promise<ResponseAreas> => {
    try {
        const baseUrl = `${process.env.API_BACKEND}/v1/areas/area/?limit=99`;
        const response = await axiosApi.get<ResponseAreas>(baseUrl);
        return response.data;
    } catch (err) {
        console.error(err);
        return { areas: [] };
    }
}