import { FC } from "react";
import { Icon } from "../Icons";
import { WrapperBackButton, BackButtonIcon, BackButtonText } from "./style";


interface IPropsBackButton {
    iconColor?: string;
    reverseOrder?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    text: string;
}

export const BackButton: FC<IPropsBackButton> = ({
    iconColor, 
    reverseOrder = false, 
    onClick, 
    disabled = false, 
    text,
}) => {


    const handleClick = () => {
        if (disabled) return;
        
        if (onClick) {
            onClick();
        }
    };

    return (
        <WrapperBackButton onClick={handleClick} disabled={disabled} reverseorder={reverseOrder}>
            <BackButtonIcon iconColor={iconColor}>
                <Icon name="arrowRight" />
            </BackButtonIcon>
            <BackButtonText>{text}</BackButtonText>
        </WrapperBackButton>
    );
};