import React, { FC } from 'react'

import { NomenclatureListPreviewItem } from 'features/Booking/ui/components/Refund/NomenclatureListPreviewItem'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { OrderBooking } from 'entities/orders/model'
import { Flex } from 'shared/components/Flex'
import { Counter } from 'shared/components/Counter'
import { Checkbox } from 'shared/components/Checkbox'
import { roundNumber } from 'shared/utils/roundNumber'

import { AreaListPreviewItem } from './AreaListPreviewItem'
import { StyledDescriptionItem, StyledFooterRow } from './styles'

interface RefundItemProps {
    booking: OrderBooking
    onChangeBookingsToRefund: (bookingId: number, checked: boolean) => void
    onChangeNomenclaturesCount: (
        bookingId: number,
        nomenclatureId: number,
        count: number,
    ) => void
    getNomenclaturesCount: (bookingId: number, nomenclatureId: number) => number
    isBookingChecked: (bookingId: number) => boolean
    isNomenclatureChecked: (
        bookingId: number,
        nomenclatureId: number,
    ) => boolean
    getAreaCount: (bookingId: number) => number
    onChangeBookingsAreaCountToRefund: (
        bookingId: number,
        count: number,
    ) => void
}

export const RefundItem: FC<RefundItemProps> = ({
    booking,
    onChangeBookingsToRefund,
    isBookingChecked,
    isNomenclatureChecked,
    onChangeNomenclaturesCount,
    getNomenclaturesCount,
    getAreaCount,
    onChangeBookingsAreaCountToRefund,
}) => {
    if (!booking?.totalPriceWithDiscount && !booking?.areaPrice) {
        return null
    }

    const areaPrice = roundNumber(booking?.areaPrice)
    const areaAbsolutDiscount = roundNumber(booking?.areaAbsolutDiscount)
    const bookingRange = getBookingRange(booking)
    const hoursCount = getAreaCount(booking.id)

    return (
        <StyledDescriptionItem>
            <Flex flexDirection='column' gap={5}>
                <StyledFooterRow>
                    <Flex gap={5}>
                        <Checkbox
                            name='booking_selector'
                            checked={isBookingChecked(booking.id)}
                            onChange={event =>
                                onChangeBookingsToRefund(
                                    booking.id,
                                    event.target.checked,
                                )
                            }
                        />
                        <AreaListPreviewItem
                            areaPrice={areaPrice ?? 0}
                            areaDiscount={areaAbsolutDiscount ?? 0}
                            range={bookingRange.label}
                            areaName={booking?.area?.name ?? ''}
                        />
                    </Flex>
                    <Counter
                        count={hoursCount}
                        onDecreaseCount={() =>
                            onChangeBookingsAreaCountToRefund(
                                booking.id,
                                hoursCount - 1,
                            )
                        }
                        onIncreaseCount={() =>
                            onChangeBookingsAreaCountToRefund(
                                booking.id,
                                hoursCount + 1,
                            )
                        }
                    />
                </StyledFooterRow>

                <Flex flexDirection='column' style={{ marginLeft: 20, gap: 5 }}>
                    {Boolean(booking?.nomenclatures?.length) && (
                        <>
                            {booking.nomenclatures
                                .filter(nomenclature => nomenclature.count > 0)
                                .map(nomenclature => {
                                    const count = getNomenclaturesCount(
                                        booking.id,
                                        nomenclature.id,
                                    )
                                    return (
                                        <StyledFooterRow key={nomenclature.id}>
                                            <Flex gap={5}>
                                                <Checkbox
                                                    name='asd'
                                                    checked={isNomenclatureChecked(
                                                        booking.id,
                                                        nomenclature.id,
                                                    )}
                                                    onChange={event =>
                                                        onChangeNomenclaturesCount(
                                                            booking.id,
                                                            nomenclature.id,
                                                            event.target.checked
                                                                ? Math.max(
                                                                      count,
                                                                      1,
                                                                  )
                                                                : 0,
                                                        )
                                                    }
                                                />
                                                <StyledDescriptionItem>
                                                    <NomenclatureListPreviewItem
                                                        nomenclature={
                                                            nomenclature
                                                        }
                                                    />
                                                </StyledDescriptionItem>
                                            </Flex>
                                            <Counter
                                                count={getNomenclaturesCount(
                                                    booking.id,
                                                    nomenclature.id,
                                                )}
                                                onDecreaseCount={() =>
                                                    onChangeNomenclaturesCount(
                                                        booking.id,
                                                        nomenclature.id,
                                                        count - 1,
                                                    )
                                                }
                                                onIncreaseCount={() =>
                                                    onChangeNomenclaturesCount(
                                                        booking.id,
                                                        nomenclature.id,
                                                        count + 1,
                                                    )
                                                }
                                            />
                                        </StyledFooterRow>
                                    )
                                })}
                        </>
                    )}
                </Flex>
            </Flex>
        </StyledDescriptionItem>
    )
}
