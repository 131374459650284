import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBookingCounterparty } from 'features/Booking/model/slices'
import { Counterparty, useCounterpartyAsyncAutocompleteOptions } from 'entities/counterparties/model'

import { emptyCall } from './getDynamicCreationReducerHook'

export const useBookingDynamicCreationReducers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { refetch } = useCounterpartyAsyncAutocompleteOptions()

    const postCreateAction = async (counterparty: Counterparty) => {
        await refetch()

        dispatch(setBookingCounterparty({ counterparty }))
        navigate(-1)
    }

    const onCancel = () => {
        navigate(-1)
    }

    return {
        beforeCreateAction: emptyCall,
        postCreateAction,
        onCancel,
    }
}
