import { axiosApi } from 'shared/api/axios'
import { GetCounterpartyResponseDto } from '../model/dtoTypes'
import {
    Counterparty,
    UseCounterpartyAsyncAutocompleteOptionsParams,
} from '../model'
import { adaptCounterpartiesDTO } from '../model/adapters/adaptCounterpartiesDTO'

export const getCounterparties = async (
    params: UseCounterpartyAsyncAutocompleteOptionsParams,
): Promise<Counterparty[]> => {
    try {
        const response = await axiosApi.get<GetCounterpartyResponseDto>(
            `${process.env.API_BACKEND}/v1/counterparties/counterparty/`,
            {
                params,
            },
        )
        return response.data.counterparties.map(adaptCounterpartiesDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка')
    }
}
