import React, { FC } from 'react'
import { useBookingFormController } from 'features/Booking/model/hooks/useBookingFormController'
import { NomenclaturesInput } from 'entities/bookings/ui'
import { Button } from 'shared/components/Button'
import { Tab, Tabs } from 'shared/components/Tabs'
import { Icon } from 'shared/components/Icons'
import { AreaButton } from './BookingFormAreaButton'
import { ActiveBookingFormTab } from '../../../model/slices'
import { BookingFormDateSettings } from './BookingFormDateSettings'
import { StyledBookingsContainer } from './styles'

interface BookingFormProps {
    isPreview: boolean
}

export const BookingForm: FC<BookingFormProps> = ({ isPreview }) => {
    const {
        isLoading,
        activeBooking,
        activeBookingId,
        bookings,
        activeTab,
        disabledTab,
        isButtonDisabled,

        setActiveTab,
        onAddNomenclature,
        onRemoveNomenclature,

        onChangeActiveBookingId,
        onAddBooking,
        onRemoveBooking,

        onGoNext,
    } = useBookingFormController()

    return (
        <div className='content'>
            <StyledBookingsContainer>
                {bookings.map(booking => (
                    <AreaButton
                        key={booking.id}
                        onClick={() => onChangeActiveBookingId(booking.id)}
                        onCrossClick={
                            isPreview
                                ? undefined
                                : () => onRemoveBooking(booking.id)
                        }
                        text={
                            booking?.area?.name?.length
                                ? booking.area.name
                                : 'Новый зал'
                        }
                        isActive={activeBookingId === booking.id}
                    />
                ))}
                <Button
                    disabled={isPreview}
                    onClick={onAddBooking}
                    padding={8}
                >
                    <Icon name='addRoundedIcon' />
                </Button>
            </StyledBookingsContainer>

            <Tabs
                onTabChange={setActiveTab}
                activeTabIndex={activeTab}
                disabledTabIndex={disabledTab}
            >
                <Tab label='Дата и время'>
                    <BookingFormDateSettings
                        activeBooking={activeBooking ?? null}
                        isLoading={isLoading}
                        isPreview={isPreview}
                    />
                </Tab>
                <Tab label='Еда и оборудование'>
                    <NomenclaturesInput
                        disabled={isLoading || isPreview}
                        values={activeBooking?.nomenclatures}
                        onAddNomenclature={onAddNomenclature}
                        onRemoveNomenclature={onRemoveNomenclature}
                    />
                </Tab>
            </Tabs>
            {!isPreview && (
                <Button disabled={isButtonDisabled} onClick={onGoNext}>
                    {activeTab === ActiveBookingFormTab.Booking
                        ? 'Сохранить'
                        : 'Далее'}
                </Button>
            )}
        </div>
    )
}
