import React from 'react'
import { Nomenclature as OrderNomenclature } from '../../orders/model'
import {
    NomenclatureDiscountLabel,
    NomenclatureLabelContainer,
    NomenclatureOldPriceLabel,
} from './styles'
import { roundNumber } from 'shared/utils/roundNumber'

export const NomenclatureLabel = ({
    nomenclature,
}: {
    nomenclature: OrderNomenclature
}) => {
    const isPercentDiscount =
        nomenclature.percentDiscount && nomenclature.percentDiscount > 0
    const isAbsolutDiscount =
        nomenclature.absolutDiscount && nomenclature.absolutDiscount > 0
    const percentDiscount = nomenclature.percentDiscount
    const absolutDiscount = nomenclature.absolutDiscount

    const discountLabel = isPercentDiscount
        ? `-${percentDiscount}%`
        : isAbsolutDiscount
          ? `-${absolutDiscount}₽`
          : ''

    const newPrice = roundNumber(nomenclature.totalPriceWithDiscount)
    const oldPrice = roundNumber(nomenclature.totalPrice)
    const isPriceChanged = newPrice.toString() !== oldPrice.toString()
    const name = nomenclature.name
    const count = nomenclature.count > 1 ? `(${nomenclature.count}) ₽` : ' ₽'
    const space = ' '

    return (
        <NomenclatureLabelContainer>
            {name}
            {space}
            {Boolean(discountLabel.length) && (
                <NomenclatureDiscountLabel>
                    ({discountLabel})
                </NomenclatureDiscountLabel>
            )}
            {count}
            {newPrice}
            {space}
            {isPriceChanged && (
                <NomenclatureOldPriceLabel>
                    {roundNumber(nomenclature.totalPrice)}
                </NomenclatureOldPriceLabel>
            )}
        </NomenclatureLabelContainer>
    )
}
//
// > = ({ nomenclature, range }) => (
//     <li>
//         {nomenclature.name}{' '}
//         {nomenclature.price && nomenclature.absolutDiscount > 0 ? (
//             <>
//                 <NomenclatureOldPriceLabel>
//                     ₽{nomenclature.price}
//                 </NomenclatureOldPriceLabel>{' '}
//                 ₽{nomenclature.price - nomenclature.absolutDiscount}
//             </>
//         ) : (
//             <>₽{nomenclature.price}</>
//         )}{' '}
//         ({nomenclature.count}шт) {nomenclature.type === 0 && `x ${range}ч`}
//     </li>
// )