import styled from "styled-components";

export const ChartPieStyled = styled.div`
    padding: 16px;
    background-color: #FAFCFF;
    border-radius: 8px;
    width: 25%;
`;

export const ChartPieTitle = styled.div`
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
`;

export const ChartPieContainer = styled.div`
    width: 100%;
    max-width: 172px;
    height: 172px;
    margin: 0 auto;
`;