import { axiosApi } from "shared/api/axios";

export const deleteMethodPayments = async (id: number): Promise<any> => {
    try {
        const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/payments/payment/methods/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
