import styled from "styled-components";

export const ChartLineStyled = styled.div`
    width: 100%;
    .chartLine .apexcharts-canvas{
        width: 100%!important;
        .apexcharts-svg.apexcharts-zoomable.hovering-zoom {
            width: 100%!important;
        }
        svg {
            width: 100%!important;
        }
    }
`;

export const ChartLineInfoBlock = styled.div`
    display: flex;

`;

export const ChartLineTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #1C1C1C;
    padding-right: 16px;
    border-right: 1px solid #e4e4e4;
    margin-right: 25px;
`;

export const ChartLineButton = styled.button<{bgcolor: string}>`
    border: none;
    outline: none;
    padding: 0;
    background: none;
    font-size: 12px;
    line-height: 18px;
    color: #1C1C1C;
    margin-right: 33px;
    position: relative;
    padding-left: 11px;
    display: flex;
    align-items: center;
    &:last-child {
        margin-right: 0;
    }
    &::before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: ${(props) => props.bgcolor || '#e7e7e7'};
        border-radius: 50px;
        position: absolute;
        left: 0;
    }
`;