import { axiosApi } from "shared/api/axios";
import { IPropsCertificate, IPropsCertificates } from "../types/CertificateTypes";

// TODO: Rewrite and add adapters
const getCertificate = async (counterparty_id: string | undefined) => {
    const response = await axiosApi.get<IPropsCertificates>(`${process.env.API_BACKEND}/v1/certificates/certificate/?counterparty_id=${counterparty_id}`);
    return response.data;
};

const createCertificate = async (certificateData: IPropsCertificate) => {
    const response = await axiosApi.post(`${process.env.API_BACKEND}/v1/certificates/certificate/`, certificateData);
    return response.data;
};

const deleteCertificate = async (certificateId: number) => {
    const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/certificates/certificate/${certificateId}`);
    return response.data;
};

export const useCertificates = () => {

    const handleCreateCertificate = async (certificateData: IPropsCertificate) => {
        await createCertificate(certificateData);
    };

    const handleDeleteCertificate = async (certificateId: number) => {
        await deleteCertificate(certificateId);
    };

    const handleGetCertificate = async (counterparty_id: string | undefined) => {
        return await getCertificate(counterparty_id);
    };

    return {
        handleCreateCertificate,
        handleDeleteCertificate,
        handleGetCertificate
    }
}
