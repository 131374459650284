import { FC } from 'react'
import { DropdownButton } from 'shared/components/Dropdown/DropdownButton'
import { BackButton } from 'shared/components/BackButton'
import { Icon } from 'shared/components/Icons'
import {
    HeaderContentItem,
    HeaderContainer,
    StyledDeleteButton,
} from './styles'

interface HeaderProps {
    handleCloseSidebar: () => void
    onDeleteOrder: () => void
    isPreview: boolean
    goFromPreviewToEdit: () => void
    onRemoveBooking: () => void
    onGoToPaymentRedirectUrl: () => void
    onGoToPayment: () => void
    onGoRefund: () => void
    isPaid: boolean
    isPastBooking: boolean
    isOrderExist: boolean
}

export const Header: FC<HeaderProps> = ({
    isPreview,
    goFromPreviewToEdit,
    handleCloseSidebar,
    onDeleteOrder,
    onRemoveBooking,
    onGoToPaymentRedirectUrl,
    onGoToPayment,
    onGoRefund,
    isPaid,
    isPastBooking,
    isOrderExist,
}) => {
    const editButton = {
        title: 'Редактировать бронь',
        onClick: goFromPreviewToEdit,
    }

    const refund = {
        title: 'Возврат',
        onClick: onGoRefund,
    }

    const paidButtons = [
        {
            title: 'Ссылка на оплату',
            onClick: onGoToPaymentRedirectUrl,
        },
        {
            title: 'Удалить бронирование',
            isErrorColor: true,
            onClick: onRemoveBooking,
        },
        {
            title: 'Удалить заказ',
            isErrorColor: true,
            onClick: onDeleteOrder,
        },
    ]

    const notPaidButtons = [
        {
            title: 'Оплатить',
            onClick: onGoToPayment,
        },
        {
            title: 'Ссылка на оплату',
            onClick: onGoToPaymentRedirectUrl,
        },
        {
            title: 'Удалить бронирование',
            isErrorColor: true,
            onClick: onRemoveBooking,
        },
        {
            title: 'Удалить заказ',
            isErrorColor: true,
            onClick: onDeleteOrder,
        },
    ]

    const buttons = isPaid
        ? isPastBooking
            ? paidButtons
            : [refund, ...paidButtons]
        : notPaidButtons
    const buttonsWithCheck = isPastBooking ? buttons : [editButton, ...buttons]

    return (
        <HeaderContainer>
            <HeaderContentItem>
                <div className='wrapper'>
                    <BackButton
                        text='Назад'
                        onClick={handleCloseSidebar}
                    />
                </div>
            </HeaderContentItem>
            <HeaderContentItem>
                {isPreview && <DropdownButton buttons={buttonsWithCheck} />}
                {!isPreview && isOrderExist && (
                    <StyledDeleteButton
                        onClick={onDeleteOrder}
                    >
                        <Icon name='delete' />
                    </StyledDeleteButton>
                )}
            </HeaderContentItem>
        </HeaderContainer>
    )
}
