import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'

import {
    clearBookingState,
    selectBookingOriginalOrder,
    selectIsCompanyBookingForm,
} from 'features/Booking/model/slices'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { useFillStore } from 'features/Booking/model/hooks/useFillStore'
import {
    PaymentMethodLabels,
    PaymentMethodName,
    RefundBooking,
    RefundPaymentRequest,
} from 'entities/payments/model'
import { PaymentApi } from 'entities/payments/api'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import { NomenclaturesType } from 'entities/nomenclatures/model'
import { getNomenclatureDiscount } from 'entities/nomenclatures/model/utils'

export const refundWayOptions: AutocompleteOption[] = [
    {
        label: PaymentMethodLabels.CASH,
        value: PaymentMethodName.Cash,
    },
    {
        label: PaymentMethodLabels.ONLINE,
        value: PaymentMethodName.Online,
    },
    {
        label: PaymentMethodLabels.CERTIFICATE,
        value: PaymentMethodName.Certificate,
    },
]

export const companyRefundWayOptions: AutocompleteOption[] = [
    ...refundWayOptions,
    {
        label: PaymentMethodLabels.ACCOUNT,
        value: PaymentMethodName.Account,
    },
]

export const useOrderRefundController = () => {
    const dispatch = useDispatch()

    const originalOrder = useSelector(selectBookingOriginalOrder)
    const [currentRefundWay, setCurrentRefundWay] =
        useState<AutocompleteOption | null>(null)
    const isCompany = useSelector(selectIsCompanyBookingForm)
    const [selectedBookings, setSelectedBookings] = useState<RefundBooking[]>(
        [],
    )
    const updateBookings = useFillStore()

    const getNomenclaturesCount = useCallback(
        (bookingId: number, nomenclatureId: number) => {
            return (
                selectedBookings
                    .find(b => b.booking_id === bookingId)
                    ?.nomenclatures?.find(n => n.id === nomenclatureId)
                    ?.count || 0
            )
        },
        [selectedBookings],
    )

    const isBookingChecked = useCallback(
        (bookingId: number) =>
            selectedBookings.some(booking => {
                const isChecked = booking.booking_id === bookingId

                const orderBooking = originalOrder?.bookings.find(
                    b => b.id === booking.booking_id,
                )

                if (!orderBooking) {
                    return isChecked
                }

                const isFullRefund =
                    orderBooking.nomenclatures.length ===
                    (booking?.nomenclatures?.length ?? 0)

                return isChecked && isFullRefund
            }),
        [selectedBookings],
    )

    const getAreaCount = useCallback(
        (bookingId: number) =>
            selectedBookings.find(b => b.booking_id === bookingId)?.area_hours
                ?.count ?? 0,
        [selectedBookings],
    )

    const isNomenclatureChecked = useCallback(
        (bookingId: number, nomenclatureId: number) =>
            getNomenclaturesCount(bookingId, nomenclatureId) > 0,
        [getNomenclaturesCount],
    )

    const getDefaultBooking = (bookingId: number): RefundBooking => {
        const booking = originalOrder?.bookings.find(b => b.id === bookingId)

        if (!booking) {
            return {
                booking_id: bookingId,
            }
        }

        return {
            booking_id: bookingId,
            area_hours: {
                count: getBookingRange(booking)?.diff,
            },
            nomenclatures: [
                ...booking.nomenclatures.map(nomenclature => ({
                    id: nomenclature.id,
                    count: nomenclature.count,
                })),
            ],
        }
    }

    const onChangeBookingsToRefund = useCallback(
        (bookingId: number, checked: boolean) => {
            if (checked) {
                const booking = originalOrder?.bookings.find(
                    b => b.id === bookingId,
                )
                if (booking) {
                    setSelectedBookings(prev => [
                        ...prev.filter(b => b.booking_id !== bookingId),
                        getDefaultBooking(bookingId),
                    ])
                }
            } else {
                setSelectedBookings(prev =>
                    prev.filter(b => b.booking_id !== bookingId),
                )
            }
        },
        [originalOrder],
    )

    const onChangeBookingsAreaCountToRefund = useCallback(
        (bookingId: number, count: number) => {
            const booking = originalOrder?.bookings.find(
                b => b.id === bookingId,
            )

            if (!booking) {
                return
            }

            const currentBooking = selectedBookings.find(
                booking => booking.booking_id === bookingId,
            )
            const newAreaHours = Math.min(
                Math.max(count, 0),
                getBookingRange(booking).diff,
            )

            if (newAreaHours === 0) {
                return setSelectedBookings(prev => [
                    ...prev.filter(b => b.booking_id !== bookingId),
                ])
            }
            setSelectedBookings(prev => [
                ...prev.filter(b => b.booking_id !== bookingId),
                {
                    ...(currentBooking ?? getDefaultBooking(bookingId)),
                    area_hours: { count: newAreaHours },
                },
            ])
        },
        [originalOrder],
    )

    const onChangeNomenclaturesCount = useCallback(
        (bookingId: number, nomenclatureId: number, count: number) => {
            setSelectedBookings(prev => {
                const booking = prev.find(b => b.booking_id === bookingId)
                const orderBooking = originalOrder?.bookings.find(
                    b => b.id === bookingId,
                )
                const orderBookingNom = orderBooking?.nomenclatures.find(
                    n => n.id === nomenclatureId,
                )
                const validCount = Math.max(
                    Math.min(orderBookingNom?.count ?? count, count),
                    0,
                )

                if (!booking) {
                    return [
                        ...prev,
                        {
                            booking_id: bookingId,
                            nomenclatures: [
                                { id: nomenclatureId, count: validCount },
                            ],
                        },
                    ]
                }

                const updatedNomenclatures =
                    booking.nomenclatures?.map(n =>
                        n.id === nomenclatureId
                            ? { ...n, count: validCount }
                            : n,
                    ) || []

                if (!updatedNomenclatures.some(n => n.id === nomenclatureId)) {
                    updatedNomenclatures.push({
                        id: nomenclatureId,
                        count: validCount,
                    })
                }

                const validUpdatedNomenclatures = updatedNomenclatures?.filter(
                    n => n.count > 0,
                )

                const newBookings = prev.map(booking =>
                    booking.booking_id === bookingId
                        ? {
                              ...booking,
                              nomenclatures: validUpdatedNomenclatures,
                          }
                        : booking,
                )

                return newBookings.filter(
                    booking => (booking?.nomenclatures?.length ?? 0) > 0,
                )
            })
        },
        [originalOrder],
    )

    const refund = async () => {
        if (!originalOrder?.id || !currentRefundWay?.value) {
            return
        }

        const isFullRefund = selectedBookings.every(b =>
            isBookingChecked(b.booking_id),
        )

        const bookingsToRefund: RefundPaymentRequest = isFullRefund
            ? {
                  is_full_refund: true,
                  type: currentRefundWay.value as PaymentMethodName,
              }
            : {
                  bookings: selectedBookings,
                  type: currentRefundWay.value as PaymentMethodName,
                  is_full_refund: false,
              }
        await PaymentApi.refundOrder(originalOrder.id, bookingsToRefund)
        await updateBookings(originalOrder.id, undefined, true)
        handleCloseSidebar()
    }

    const fullRefund = async () => {
        if (!originalOrder?.id || !currentRefundWay?.value) {
            return
        }

        const fullBookingsToRefund: RefundPaymentRequest = {
            is_full_refund: true,
            type: currentRefundWay.value as PaymentMethodName,
        }
        await PaymentApi.refundOrder(originalOrder.id, fullBookingsToRefund)
        await updateBookings(originalOrder.id, undefined, true)
        handleCloseSidebar()
    }

    const handleCloseSidebar = () => {
        dispatch(clearBookingState())
        dispatch(closeSidebar())
    }

    const totalPrice = useMemo(() => {
        return selectedBookings.reduce((sum, booking) => {
            const orderBooking = originalOrder?.bookings.find(
                b => b.id === booking.booking_id,
            )

            if (!orderBooking) {
                return sum
            }

            const range = getBookingRange(orderBooking)?.diff ?? 1

            const isFullRefund =
                orderBooking.nomenclatures.length ===
                (booking?.nomenclatures?.length ?? 0)

            const nomenclaturesPrice =
                booking.nomenclatures?.reduce((nSum, nomenclature) => {
                    const orderNomenclature = orderBooking?.nomenclatures.find(
                        refundNomenclature =>
                            refundNomenclature.id === nomenclature.id,
                    )

                    if (!orderNomenclature || nomenclature.count < 1) {
                        return nSum
                    }

                    const discount =
                        getNomenclatureDiscount(
                            orderNomenclature,
                        ).absolutDiscountTotal
                    const coefficient =
                        orderNomenclature.type ===
                        NomenclaturesType.AdditionalService
                            ? range
                            : 1

                    const priceWithDiscount =
                        (orderNomenclature?.price ?? 0) - discount
                    const price =
                        coefficient * nomenclature.count * priceWithDiscount

                    return nSum + price
                }, 0) ?? 0

            return (
                sum +
                (isFullRefund
                    ? (orderBooking.areaTotalPriceWithDiscount ?? 0)
                    : 0) +
                nomenclaturesPrice
            )
        }, 0)
    }, [selectedBookings, originalOrder])

    return {
        totalPrice,
        isCompany,
        selectedBookings,
        originalOrder,
        currentRefundWay,

        setCurrentRefundWay,
        onChangeNomenclaturesCount,
        getNomenclaturesCount,
        isBookingChecked,
        isNomenclatureChecked,
        refund,
        fullRefund,
        onChangeBookingsToRefund,
        onChangeBookingsAreaCountToRefund,
        getAreaCount,
    }
}
