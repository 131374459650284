import { CalendarEvent } from 'features/ReactBigCalendar/ReactBigCalendar'
import { Nomenclature, DetailOrder, OrderBooking } from 'entities/orders/model'
import { Counterparty } from 'entities/counterparties/model'
import { ViewModeType } from '../../../BookingCalendarController/model'

export const BookingStep = {
    GeneralInfo: 'generalInfo',
    Booking: 'booking',
    Payment: 'payment',
    Documents: 'documents',
} as const

export const BookingMode = {
    Create: 'create',
    Edit: 'edit',
} as const

export const OrderViewMode = {
    Preview: 'preview',
    Edit: 'edit',
    Refund: 'refund',
} as const

export type BookingStepType = (typeof BookingStep)[keyof typeof BookingStep]
export type BookingModeType = (typeof BookingMode)[keyof typeof BookingMode]
export type OrderViewModeType =
    (typeof OrderViewMode)[keyof typeof OrderViewMode]

export enum ActiveBookingFormTab {
    Booking,
    Nomenclatures,
}

export type OrderBookingWithOptionsFields = {
    area?: {
        id?: number | null
        name?: string | null
    }
    comment?: string | null
    membersCount?: number | null
    endTime?: Date | null
    startTime?: Date | null
    areaPrice?: number | null
    nomenclaturesPrice?: number | null
    nomenclatures?: Nomenclature[]
}

export interface BookingState {
    currentStep: BookingStepType
    mode: BookingModeType
    orderViewMode: OrderViewModeType

    activeBookingId: number | null
    bookingIdToEdit: number | null

    originalOrder: DetailOrder | null
    bookingActiveTab: ActiveBookingFormTab
    isEditStateInitialising: boolean
    today: string
    view: ViewModeType
    isDirty: boolean
    isCompany: boolean | null

    orderId: number | null
    counterparty: Counterparty | null

    totalPrice: number | null

    bookings: OrderBooking[]

    currentCalendarEvents: CalendarEvent[]
}
