import { axiosApi } from 'shared/api/axios'
import { setUTCTimezone } from 'shared/utils/timezone'
import { OrderEndpoint } from './constants'
import { AddBookingRequest } from '../model'
import { AddBookingToOrderResponseDTO } from '../model/dtoTypes'

export const addBookingToOrder = async ({
    orderId,
    areaId,
    startTime,
    endTime,
    comment,
    membersCount,
    areaAbsolutDiscount,
    areaPercentDiscount,
}: AddBookingRequest) => {
    try {
        const response = await axiosApi.post<AddBookingToOrderResponseDTO>(
            OrderEndpoint.bookings,
            {
                order_id: orderId,
                area_id: areaId,
                start_time: setUTCTimezone(startTime).toISOString(),
                end_time: setUTCTimezone(endTime).toISOString(),
                comment,
                area_absolut_discount: areaAbsolutDiscount,
                area_percent_discount: areaPercentDiscount,
                members_count: membersCount ?? null,
            },
        )
        return {
            bookingId: response.data.id,
            totalPrice: parseFloat(response.data.new_order_total_price),
        }
    } catch (err) {
        console.error(err)
        throw new Error(`Ошибка создания заказа ${(err as Error)?.message}`)
    }
}
