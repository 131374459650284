import { useThemeContext } from "app/providers/providers";
import { ChartPieContainer, ChartPieStyled, ChartPieTitle } from "./style"
import Chart from "react-apexcharts"; 

interface IPropsChartPie {
    title: string;
}

export const ChartPie = ({
    title,
}: IPropsChartPie) => { 

    const { theme } = useThemeContext();

    const orderOptions: ApexCharts.ApexOptions = { 
        series: [147, 357],
        chart: {
            type: 'donut',
            width: '100%',
        },
        dataLabels: {
            enabled: false,
        },
        states: {
            normal: {
                filter: {
                    type: "none"
                }
            },
            active: {
                filter: {
                    type: "none"
                }
            },
            hover: {
                filter: {
                    type: "none"
                }
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false,
                        value: {
                            show: false,
                        },
                        name: {
                            show: false,
                        },
                        total: {
                            show: false,
                        }
                    }
                }
            }
        },
        labels: ['Юр. лица', 'Физ. лица'],
        colors: [theme.themeColors.main, "#C3DCED"],
        legend: { 
            show: false 
        },
        tooltip: {
            enabled: false,
        }
    }; 
    

    return ( 
        <ChartPieStyled>
            <ChartPieTitle>{title}</ChartPieTitle>
            <ChartPieContainer>
                <Chart 
                    className="chartLine"
                    options={orderOptions} 
                    series={orderOptions.series} 
                    type="donut"
                    width={"100%"}
                    height={244} 
                />
                {/* <div style={{ textAlign: 'center' }}>
                    <div>{357} Всего заказов</div>
                </div> */}
            </ChartPieContainer>
        </ChartPieStyled>
    ) 
}