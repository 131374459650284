import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "features/auth/services/AuthServices";

interface IPropsInitialState {
  isAuth: boolean | null;
  isAuthInProgress: boolean;
  error: any;
}

const initialState: IPropsInitialState = {
  isAuth: null,
  isAuthInProgress: false,
  error: null,
};

export const login = createAsyncThunk(`${process.env.API_OAUTH}/v1/auth/login`, async ({ login, password }: any, { rejectWithValue }) => {
  try {
    const response = await AuthService.login(login, password);
    const { tokens } = response.data;

    localStorage.setItem('token', tokens.access);
    localStorage.setItem('refresh', tokens.refresh);

    const userResponse = await AuthService.auth();
    localStorage.setItem('users', JSON.stringify(userResponse.data));

    const permissionsResponse = await AuthService.getPermissions(userResponse.data.role.id);
    localStorage.setItem('permissionsUsers', JSON.stringify(permissionsResponse.data.permissions));
  } catch (err) {
    return rejectWithValue('Ошибка входа!');
  }
});

export const checkAuth = createAsyncThunk(`${process.env.API_BACKEND}/v1/users/user/info/me`, async (_, { rejectWithValue }) => {
  try {
    const response = await AuthService.auth();
    return response.data;
  } catch (err) {
    // localStorage.removeItem('token'); 
    // localStorage.removeItem('refresh'); 
    // localStorage.removeItem('users'); 
    // localStorage.removeItem('permissionsUsers'); 
    return rejectWithValue('Ошибка авторизации');
  }
});

export const logout = createAsyncThunk(`${process.env.API_OAUTH}/v1/auth/logout`, async (_, { rejectWithValue }) => {
  try {
    await AuthService.logout();
  } catch (err) {
    return rejectWithValue('Ошибка выхода');
  } finally {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('users');
    localStorage.removeItem('permissionsUsers');
  }
});


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(login.pending, (state) => {
            state.isAuthInProgress = true;
            state.error = null;
          })
          .addCase(login.fulfilled, (state) => {
            state.isAuthInProgress = false;
            state.isAuth = true;
            // localStorage.setItem('token', action.payload.tokens.access);
            // localStorage.setItem('refresh', action.payload.tokens.refresh);
          })
          .addCase(login.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.error = action.payload;
          })

          .addCase(checkAuth.pending, (state) => {
            state.isAuthInProgress = true;
            state.isAuth = false;
            state.error = null;
          })
          .addCase(checkAuth.fulfilled, (state, action) => {
            state.isAuthInProgress = false;
            state.isAuth = true;
            localStorage.setItem('users', JSON.stringify(action.payload))
          })
          .addCase(checkAuth.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.isAuth = false;
            state.error = action.payload;
          })
          .addCase(logout.pending, (state) => {
            state.isAuthInProgress = true;
            state.error = null;
          })
          .addCase(logout.fulfilled, (state) => {
            state.isAuthInProgress = false;
            state.isAuth = false;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('users');
            localStorage.removeItem('permissionsUsers');
          })
          .addCase(logout.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.error = action.payload;
          });
      },
});

export default authSlice.reducer;