import { axiosApi } from "shared/api/axios";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;
}

export const patchNotifications = async (
    values: any
): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.patch(`${process.env.API_BACKEND}/v1/notifications/notification/`, {
            notifications: values
        });
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
}