import styled from 'styled-components'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    themeType?: 'primary' | 'outline' | 'item' | 'outline-2' | 'delete'
    padding?: number
    disabled?: boolean
    fullWidth?: boolean
}

export const StyledButton = styled.button<ButtonProps>`
    border-radius: ${({ themeType }) => {
        switch (themeType) {
            default:
                return '8px'
        }
    }};
    background: ${({ themeType, theme }) => {
        switch (themeType) {
            case 'primary':
                return theme.themeColors.main;
            case 'delete':
                return '#FC3F1D'
            default:
                return 'none'
        }
    }};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    border: ${({ themeType, theme }) => {
        switch (themeType) {
            case 'primary':
                return 'none'
            case 'outline':
                return '1px solid #8181A5'
            case 'outline-2':
                return `1px solid ${theme.themeColors.main}`;
            case 'item':
                return '1px solid #E1E4EA'
            default:
                return 'none'
        }
    }};
    padding: ${({ themeType, padding }) => {
        if (padding !== undefined && padding !== null) {
            if (padding === 0) {
                return '0'
            }
            return `0 ${padding}px`
        }
        switch (themeType) {
            case 'item':
                return '0'
            default:
                return '0 18px'
        }
    }};
    width: ${({ themeType, fullWidth }) => {
        if (fullWidth) {
            return '100%'
        }
        switch (themeType) {
            case 'item':
                return '39px'
            default:
                return 'auto'
        }
    }};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${({ themeType }) => {
            switch (themeType) {
                case 'primary':
                    return '#fff'
                case 'delete':
                    return '#fff'
                case 'outline':
                    return '#8181A5'
                case 'outline-2':
                    return '#282A42'
            }
        }};
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: ${({ themeType }) => {
            switch (themeType) {
                case 'outline-2':
                    return '600'
                default:
                    return '700'
            }
        }};
        line-height: normal;
    }
    .loader {
        width: 20px;
        padding: 2px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: ${({ themeType, theme }) => {
            switch (themeType) {
                case 'primary':
                    return '#fff'
                case 'delete':
                    return '#fff'
                default:
                    return theme.themeColors.main
            }
        }};
        --_m: conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }
`
