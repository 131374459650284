import { StyledLoader } from "./style"

interface IPropsLoader {
    bgBlur?: boolean;
}

export const Loader = ({bgBlur}: IPropsLoader) => {
    return (
        <StyledLoader
            bgBlur={bgBlur}
        >
            <div className="loader"></div>
        </StyledLoader>
    )
}