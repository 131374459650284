import { Nomenclature } from 'entities/orders/model'

export const getNomenclatureDiscount = (nomenclature: Nomenclature) => {
    const isPercentDiscount = nomenclature.percentDiscount
        ? nomenclature.percentDiscount > 0
        : false
    const isAbsolutDiscount = nomenclature.absolutDiscount
        ? nomenclature.absolutDiscount > 0
        : false
    const percentDiscount = nomenclature.percentDiscount
    const absolutDiscount = nomenclature.absolutDiscount

    const isDiscount = isPercentDiscount || isAbsolutDiscount
    const absolutDiscountTotal = isAbsolutDiscount
        ? absolutDiscount
        : isPercentDiscount && nomenclature.price
          ? (nomenclature.price * percentDiscount) / 100
          : 0

    return {
        isDiscount,
        absolutDiscountTotal,
    }
}
