import { axiosApi } from "shared/api/axios";
import { IPropsRolesList, IPropsRolesSingle } from "../types";

export const getRoles = async (): Promise<IPropsRolesList> => {
    try {
        const response = await axiosApi.get<IPropsRolesList>(`${process.env.API_BACKEND}/v1/roles/role/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const getRolesSingle = async (id: number): Promise<IPropsRolesSingle> => {
    try {
        const response = await axiosApi.get<IPropsRolesSingle>(`${process.env.API_BACKEND}/v1/roles/role/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
