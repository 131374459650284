import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Table } from "shared/components/Table";
import { WrapperOrderTable } from "./style";
import { getOrderClients } from "../api/getOrderClients";
import { columnOrderClients } from "../model/columns";
import { useState } from "react";

export const OrdersTable = ({
    id
}: {
    id?: string
}) => {

    const [currentPage, setCurrentPage] = useState(1);

    const { isLoading, isError, data } = useQuery({
        queryKey: ["getOrdersClients", id, currentPage], 
        queryFn: () => {
            return getOrderClients(currentPage, id);
        },
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60,
        refetchOnWindowFocus: false
    });

    return (
        <>
            {!isLoading && data && (
                <WrapperOrderTable>
                    <Table 
                        className="clientsTable"
                        navigation="/booking"
                        data={data.orders}
                        columns={columnOrderClients} 
                        columnWidths={['100px']}
                        isLoading={isLoading}
                        totalPages={data.pagination.total_pages}
                        page={currentPage}
                        isError={isError}
                        disableSelection={true}
                        disableSorting={true}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </WrapperOrderTable>
            )}
        </>
    )
}