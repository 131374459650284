import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
    isAlreadyHaveBookingWithThatHour,
    isAlreadyHaveEventWithThatTime,
    isTimeUnavailableByArea,
} from 'features/BookingCalendarController/model/hooks/useCalendarActions'
import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { OrderBooking } from 'entities/orders/model'
import {
    editBooking,
    OrderBookingWithOptionsFields,
    selectBookingFormMode,
    BookingMode,
    selectCurrentCalendarEvents,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'

const normalizeDateTime = (newStart: moment.Moment, newEnd: moment.Moment) => {
    const now = moment()

    newStart.set({ second: 0, millisecond: 0 })
    newEnd.set({ second: 0, millisecond: 0 })

    const validStart = newStart.isBefore(now) ? now : newStart
    let validEnd = newEnd.isBefore(now) ? now : newEnd

    if (validStart.isSameOrAfter(validEnd)) {
        validEnd = validStart.clone().add(1, 'hour')
    }

    if (validStart.isSameOrAfter(validEnd.clone().subtract(1, 'hour'))) {
        validEnd = validStart.clone().add(1, 'hour')
    }

    return { validStart, validEnd }
}

export const useBookingFormDateTimeHelper = (
    activeBooking: OrderBooking | null,
) => {
    const dispatch = useDispatch()
    const mode = useSelector(selectBookingFormMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const bookings = useSelector(selectCurrentCalendarEvents)

    const start = activeBooking?.startTime ?? null
    const end = activeBooking?.endTime ?? null

    const { checkIsBookingAlreadyCreated } = useIsBookingsAlreadyCreated()
    const isPastCompleteEvent = useMemo(() => {
        if (!checkIsBookingAlreadyCreated(activeBooking)) {
            return false
        }
        if (!start || !end || !isEdit) {
            return false
        }
        const now = moment()
        return (
            moment(start).add(5, 'minutes').isBefore(now) ||
            moment(end).add(5, 'minutes').isBefore(now)
        )
    }, [start, end, isEdit])

    const isDisableDateEditing = isEdit && isPastCompleteEvent

    const onEditBooking = (
        bookingId: number,
        body: OrderBookingWithOptionsFields,
    ) => {
        dispatch(editBooking({ bookingId, body }))
    }

    const onEditStartEndDate = (newDateRange: [Date, Date] | null) => {
        if (activeBooking) {
            if (!newDateRange) {
                onEditBooking(activeBooking.id, {
                    startTime: null,
                    endTime: null,
                })
                return
            }
            const [startDate, endDate] = newDateRange

            const unavailableAreaTime = isTimeUnavailableByArea(
                startDate,
                endDate,
                activeBooking?.area?.id ?? null,
                areas,
            )

            if (unavailableAreaTime) {
                return
            }

            if (
                isAlreadyHaveEventWithThatTime(
                    startDate,
                    endDate,
                    activeBooking?.area?.id ?? null,
                    bookings,
                    activeBooking?.id,
                )
            ) {
                return
            }

            const startMoment = moment(startDate).set({
                hour: start?.getHours() ?? 0,
                minute: start?.getMinutes() ?? 0,
                second: start?.getSeconds() ?? 0,
            })
            const endMoment = moment(endDate).set({
                hour: end?.getHours() ?? 0,
                minute: end?.getMinutes() ?? 0,
                second: end?.getSeconds() ?? 0,
            })

            const { validStart, validEnd } = normalizeDateTime(
                startMoment,
                endMoment,
            )

            onEditBooking(activeBooking.id, {
                startTime: validStart.toDate(),
                endTime: validEnd.toDate(),
            })
        }
    }

    const onEditStartEndTime = (newDateRange: [Date, Date] | null) => {
        if (activeBooking) {
            if (!newDateRange) {
                onEditBooking(activeBooking.id, {
                    startTime: null,
                    endTime: null,
                })
                return
            }
            const [startTime, endTime] = newDateRange

            const unavailableAreaTime = isTimeUnavailableByArea(
                startTime,
                endTime,
                activeBooking?.area?.id ?? null,
                areas,
            )

            if (unavailableAreaTime) {
                return
            }

            if (
                isAlreadyHaveEventWithThatTime(
                    startTime,
                    endTime,
                    activeBooking?.area?.id ?? null,
                    bookings,
                    activeBooking?.id,
                )
            ) {
                return
            }

            const startMoment = moment(start || new Date()).set({
                hour: startTime.getHours(),
                minute: startTime.getMinutes(),
                second: startTime.getSeconds(),
            })
            const endMoment = moment(end || new Date()).set({
                hour: endTime.getHours(),
                minute: endTime.getMinutes(),
                second: endTime.getSeconds(),
            })

            const { validStart, validEnd } = normalizeDateTime(
                startMoment,
                endMoment,
            )

            onEditBooking(activeBooking.id, {
                startTime: validStart.toDate(),
                endTime: validEnd.toDate(),
            })
        }
    }

    const { areas } = useAreasResourcesQuery()

    const validateHour = (hour: number) => {
        const currentArea = areas.find(
            area => area.id === activeBooking?.area?.id,
        )
        if (!currentArea) {
            return null
        }

        const availableTime =
            currentArea.adBookingInfo?.availableBookingTime?.[0]

        if (!availableTime) {
            return null
        }

        if (!availableTime.start || !availableTime.end) {
            return null
        }

        const availableTimeStart = moment(
            `${moment().format('YYYY-MM-DD')} ${availableTime.start}`,
            'YYYY-MM-DD HH:mm:ss',
        )
        const availableTimeEnd = moment(
            `${moment().format('YYYY-MM-DD')} ${availableTime.end}`,
            'YYYY-MM-DD HH:mm:ss',
        )

        const hourDate = moment({
            hour,
            minute: 0,
            second: 0,
        })

        return {
            availableTimeStart,
            availableTimeEnd,
            hourDate,
        }
    }

    const customShouldDisableHour = (
        hour: number,
        _: Date,
        isStart: boolean,
        isEnd: boolean,
    ) => {
        const isAlreadyHaveBooking = activeBooking?.area?.id
            ? isAlreadyHaveBookingWithThatHour(
                  hour,
                  activeBooking.area.id,
                  bookings,
                  activeBooking?.id,
                  isStart,
                  isEnd,
              )
            : false

        if (isAlreadyHaveBooking) {
            return true
        }

        const validation = validateHour(hour)
        if (!validation) {
            return false
        }

        const { availableTimeStart, availableTimeEnd, hourDate } = validation
        return !(
            hourDate.isSameOrAfter(availableTimeStart) &&
            hourDate.isSameOrBefore(availableTimeEnd)
        )
    }

    const customShouldDisableMinutes = (
        minutes: number,
        currentValue: Date,
    ) => {
        const hour = moment(currentValue).hour()

        const validation = validateHour(hour)
        if (!validation) {
            return false
        }

        const { availableTimeStart, availableTimeEnd, hourDate } = validation

        if (
            hourDate.isSame(availableTimeStart) ||
            hourDate.isSame(availableTimeEnd)
        ) {
            return minutes !== 0
        }

        return false
    }

    return {
        isDisableDateEditing,
        start,
        end,
        onEditStartEndDate,
        onEditStartEndTime,
        customShouldDisableHour,
        customShouldDisableMinutes,
    }
}
