import { axiosApi } from "shared/api/axios";

export const getAreasSingle = async (id?: any): Promise<any> => {
    try {
        const response = await axiosApi.get<any>(`${process.env.API_BACKEND}/v1/areas/area/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};