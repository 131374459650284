import { axiosApi } from "shared/api/axios";
import { ResSingleNomenclature } from "../types/responeType";

export const getNomenclatureSingle = async (id?: any): Promise<ResSingleNomenclature> => {
    try {
        const response = await axiosApi.get<ResSingleNomenclature>(`${process.env.API_BACKEND}/v1/nomenclatures/nomenclature/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};