import styled, { css } from 'styled-components'

export const StyledFooterContainer = styled.div`
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`

export const StyledItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const StyledFooterColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledDescription = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
`

export const StyledTotalAmount = styled.span<{
    mode: 'paid' | 'not_paid' | 'total'
}>`
    ${StyledDescription};

    color: ${props =>
        props.mode === 'total'
            ? '#8181A5'
            : props.mode === 'not_paid'
              ? '#ED4627'
              : '#34BD93'};
`

export const StyledDescriptionItem = styled.span`
    ${StyledDescription};

    color: #8181a5;
    flex: 0 0 75%;
    max-width: 75%;
`

export const StyledFooterRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`

export const StyledDescriptionPrice = styled.span<{
    isHideListPadding?: boolean
}>`
    ${StyledDescription};

    color: #282a42;
    flex: 0 0 25%;
    max-width: 25%;
    margin: ${props => (props.isHideListPadding ? '0 0 0 -25px' : undefined)};
`

export const List = styled.ol`
    margin: 0;
    padding: 0 0 0 25px;
`

export const NestedList = styled.ol`
    margin: 0;
    padding: 0 0 0 25px;
`
