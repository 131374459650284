import './style.css'
import React, { FC, SyntheticEvent } from 'react'
import { InputContainer, Label } from 'shared/components/Input/style'
import {
    isDisabledBasedOnMinMaxDate,
    Locale,
} from 'shared/components/DatePickerInput/DatePickerInput'
import { StyledDateRangePicker } from 'shared/components/DateRangePickerInput/style'

export type CalendarEvent = SyntheticEvent<Element, Event>
interface DateRangePickerInputProps {
    value?: [Date, Date] | null
    onChange?: (date: [Date, Date] | null, e: CalendarEvent) => void
    placeholder?: string
    label?: string
    isLoading?: boolean
    disabled?: boolean
    required?: boolean
    editable?: boolean
    minDate?: Date
    maxDate?: Date
    customShouldDisableDate?: (date: Date) => boolean
}

export const DateRangePickerInput: FC<DateRangePickerInputProps> = ({
    value,
    onChange,
    placeholder,
    label,
    isLoading,
    disabled,
    required,
    editable,
    minDate,
    maxDate,
    customShouldDisableDate,
}) => {
    return (
        <InputContainer>
            {label && (
                <Label>
                    {required && <span className='required'>*</span>}
                    {label}
                </Label>
            )}
            <StyledDateRangePicker
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                size='lg'
                showHeader={false}
                showOneCalendar
                loading={isLoading}
                disabled={disabled}
                editable={editable}
                shouldDisableDate={(date: Date) =>
                    customShouldDisableDate
                        ? customShouldDisableDate(date) ||
                          isDisabledBasedOnMinMaxDate(date, minDate, maxDate)
                        : isDisabledBasedOnMinMaxDate(date, minDate, maxDate)
                }
                locale={Locale}
                onEntering={() => {}}
            />
        </InputContainer>
    )
}
