import { StyledStatusChips } from "features/OrdersTable/ui/style";
import moment from "moment";

export const columns = [
    {
        header: "Cумма",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "amount"
    },
    {
        header: "Способ оплаты",
        cell: (row: any) => {
            const method: string = row.renderValue();
            
            let text = "";

            if (method === "CASH") {
                text = "Наличными";
            } else if (method === "CERTIFICATE") {
                text = "Сертификатом";
            } else if (method === "ONLINE") {
                text = "Онлайн";
            } else if (method === "ACCOUNT") {
                text = "Счет на оплату";
            }

            return text;
        },
        accessorKey: "method.type"
    },
    {
        header: "Дата транзакции",
        cell: (row: any) => {
            return moment(row.renderValue()).format("DD.MM.YYYY HH:MM")
        },
        accessorKey: "created_at"
    },
    {
        header: "Статус",
        cell: (row: any) => {
            const status: string = row.renderValue();
            
            let text = "";
            let background = "";
            let color = "";
    
            if (status === "PENDING") {
                text = "В ожидании";
                background = "#FFF5CC";
                color = "#CCA300";
            } else if (status === "COMPLETED") {
                text = "Оплачено";
                background = "#D9F2DD";
                color = "#359742";
            } else if (status === "cancelled") {
                text = "FAILED";
                background = "#FFE5E5";
                color = "#FF6666";
            }
    
            return (
                <StyledStatusChips background={background} color={color}>
                    {text}
                </StyledStatusChips>
            );
        },
        accessorKey: "status"
    }, 
]
