import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { StyledItem } from "./style"
import { useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { axiosApi } from "shared/api/axios";
import { BackButton } from "shared/components/BackButton"
import { EmployeesProfile } from "features/Employees/ui/EmployeesProfile"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { FormikHelpers } from "formik"
import { EMPLOYEESLIST } from "shared/consts/routingConsts"

export const EmployeesProfilePages = () => {
    const {id} = useParams();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const handleDeleteProfile = () => {
        axiosApi.delete(`${process.env.API_BACKEND}/v1/users/user/${id}`);
        dispatch(closeModal());
        navigate(EMPLOYEESLIST)
    }

    const handleGoBack = () => {
        navigate(EMPLOYEESLIST);
    }

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">Профиль</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                {isEditable ? (
                        <>
                            <Button requiredPermission="add" themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                            <Button requiredPermission="add" themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                        </>
                    ) : (
                        <>
                            <Button requiredPermission="add" themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                            <Button 
                                requiredPermission="add"
                                themeType="delete" 
                                onClick={() => dispatch(openModal({
                                    isOpen: true,
                                    content: (
                                        <>
                                            Вы точно хотите удалить профиль сотрудника?
                                        </>
                                    ),
                                    config: {
                                        title: "Вы точно хотите удалить?",
                                        btns: [
                                            {
                                                onClick: () => handleDeleteProfile(),
                                                title: "Да",
                                                active: true
                                            },
                                            {
                                                onClick: () => dispatch(closeModal()),
                                                title: "Нет",
                                                active: false
                                            }
                                        ]
                                    }
                                }))}
                            >
                                Удалить сотрудника
                            </Button>

                        </>
                    )
                }
                </div>
            </div>
        }>
            <EmployeesProfile 
                setDisabled={setDisabled}
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
            />
        </DashboardLayout>
    )
}