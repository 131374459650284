import { axiosApi } from "shared/api/axios";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;
}

export const postEmployees = async (values: object): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.post(`${process.env.API_BACKEND}/v1/users/user/`, values);
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
}