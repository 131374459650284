import styled, { css } from "styled-components";

interface IPropsStyledLoader {
    width?: number;
    widthStroke?: number;
    background?: string;
    bgBlur?: boolean;
}

export const StyledLoader = styled.div<IPropsStyledLoader>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    ${props => props.bgBlur && css`
        backdrop-filter: blur(2px);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
    `}
    .loader {
        width: ${props => props.width ? `${props.width}px` : "30px"};
        padding: ${props => props.width ? `${props.widthStroke}px` : "8px"};
        aspect-ratio: 1;
        border-radius: 50%;
        background: ${({ background, theme }) => background || theme.themeColors.main};
        --_m: 
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: l3 1s infinite linear;
        @keyframes l3 {to{transform: rotate(1turn)}}
    }
`;
