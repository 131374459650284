import moment from "moment";

export const columnBlackList = [
    {
        header: "",
        id: "photo",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {value ? <img src={value} alt={"Фото"} /> : <div className="bodyCellImgBg"></div>}
                </div>
            )
        },
        accessorKey: "profile_photo.read_url"
    },
    {
        header: "Наименование",
        cell: (row: any) => {
            const type = row.row.original.type;
            const contact =  row.row.original.contact;
            const contactFio = `${contact.surname} ${contact.first_name} ${contact.patronymic}`
            const organization_name = row.row.original.additional_data.documents.organization_name;
            return type === 0 ? (
                <>
                    {contactFio}
                </>
            ) : (
                <div className={organization_name.length >= 15 ? "crop" : ""}>
                    {organization_name}
                </div>
            );
        },
        accessorFn: (row: any) => row?.contact,
        id: "contact__surname"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "phone"
    },
    {
        header: "Почта",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "email"
    },
    {
        header: "Количество часов брони",
        cell: (row: any) => {
            return row.renderValue() ? Math.floor(row.renderValue()) : 0;
        },
        accessorKey: "booking_hours"
    },
    {
        header: "Дата регистрации",
        cell: (row: any) => {
            return moment(row.renderValue()).format("DD.MM.YYYY")
        },
        accessorKey: "created_at"
    },
    {
        header: "Комментарий",
        cell: (row: any) => {
            return <div className="comment">{row.renderValue()}</div>
        },
        accessorKey: "comment"
    },
]