import React, { FC } from 'react'
import { StyledContainer } from 'shared/components/Counter/styles'
import { Icon } from 'shared/components/Icons'

interface CounterProps {
    count: number
    onDecreaseCount: () => void
    onIncreaseCount: () => void
    disabled?: boolean
}

export const Counter: FC<CounterProps> = ({
    count,
    onIncreaseCount,
    onDecreaseCount,
    disabled,
}) => (
    <StyledContainer aria-disabled={disabled}>
        <Icon
            style={disabled ? undefined : { cursor: 'pointer' }}
            onClick={disabled ? undefined : onDecreaseCount}
            name='minus'
        />
        <span>{count}</span>
        <Icon
            style={{ cursor: 'pointer' }}
            onClick={disabled ? undefined : onIncreaseCount}
            name='mediumPlus'
        />
    </StyledContainer>
)
