import { Icon } from "shared/components/Icons";
import { IPropsResponseCertificate } from "../types/CertificateTypes"
import { StyledCertificateBlock, StyledCertificateContent, StyledCertificateModalButton } from "./style"
import moment from "moment";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

interface IPropsModalCertificates {
    certificate: IPropsResponseCertificate
    onClose: (e: boolean) => void;
}

export const ModalCertificates = ({certificate, onClose}: IPropsModalCertificates) => {
    const componentRef = useRef(null);
    
    const printFn = useReactToPrint({
        contentRef: componentRef,
        documentTitle: `Сертификат на услуги от ${moment().format("DD.MM.YYYY")}`
    });

    return (
        <div ref={componentRef}>
            <StyledCertificateBlock >
                <StyledCertificateModalButton onClick={() => onClose(false)}>
                    <Icon name="arrowRight" />
                </StyledCertificateModalButton>
                <StyledCertificateContent>
                    <div className="CertificateContentTop">
                        <span>{moment(certificate.created_at).format("DD.MM.YYYY")}</span>
                        <span>№{certificate.id}</span>
                    </div>
                    <div className="title">
                        Сертификат
                        <span>НА УСЛУГИ</span>
                        <img src="/companyIcon.png" />
                    </div>
                    <div className="CertificateContentInfo">
                        <span>Выдан</span>
                        <div className="CertificateContentInfoTitle">
                            {certificate.counterparty_contact_info.organization_name !== null ? certificate.counterparty_contact_info.organization_name : (
                                <>
                                    {certificate.counterparty_contact_info.surname} {certificate.counterparty_contact_info.first_name} {certificate.counterparty_contact_info.patronymic}
                                </>
                            )}
                        </div>
                        <hr />
                        <div className="CertificateContentInfoTitle">{certificate.balance} ₽</div>
                    </div>
                    <div className="CertificateContentBottom">
                        <span>Срок действия до: {moment(certificate.expired_at).format("DD.MM.YYYY")}</span>
                        <span>Источник: Cтудия BACKGROUND</span>
                    </div>
                </StyledCertificateContent>
                <StyledCertificateModalButton onClick={() => printFn()}>
                    <Icon name="download" />
                </StyledCertificateModalButton>
            </StyledCertificateBlock>
        </div>
    )
}