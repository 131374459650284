import { axiosApi } from "shared/api/axios";
import { ResponseBooking } from "../types";

export const getBooking = async (id?: any): Promise<ResponseBooking> => {
    try {
        const response = await axiosApi.get<ResponseBooking>(`${process.env.API_BACKEND}/v1/bookings/order/${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения бронирования");
    }
};