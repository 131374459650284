import { DashboardLayout } from "app/layout"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "shared/components/Button"
import { TabsWrapper, WrapperSearch } from "./style"
import { Tab, Tabs } from "shared/components/Tabs"
import { Input } from "shared/components/Input"
import { Table } from "shared/components/Table"
import { useEffect, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { axiosApi } from "shared/api/axios";
import { columnBlackList } from "./model/columnBlackList"
import { useDebounce } from "shared/hooks/useDebounce"
import { columns } from "./model/columns"
import { getClients } from "./api/getClients"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { showNotification } from "shared/redux/slice/notificationSlice"
import { SortingState } from "@tanstack/react-table"
import { columnsOrg } from "./model/columnOrg"
import { CLIENTSCREATE, CLIENTSPROFILE } from "shared/consts/routingConsts"
import { Loader } from "shared/components/Loader"

export const Clients = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    const [tab, setTab] = useState<number>(0);

    const [search, setSearch] = useState<string>("");
    const [typeClient, setTypeClient] = useState(0);

    const [loading, setLoading] = useState<boolean>(false);

    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const [sorting, setSorting] = useState<SortingState>([]);

    const navigate = useNavigate();

    const debouncedSearchTerm = useDebounce(search, 1000);
    const handleClick = (to: string) => {
        navigate(to);
    }

    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handleSelectionChange = (newSelectedRows: any[]) => {
        setSelectedRows(newSelectedRows);
    };

    const hasSelectedRows = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows]);

    const { isLoading, isError, data, isFetching, isPending, refetch} = useQuery({
        queryKey: ["getClients", currentPage, typeClient, search, sorting], 
        queryFn: () => {
            const order_by = sorting.length ? sorting[0].id : "created_at";
            const order_asc = sorting.length ? sorting[0].desc === true : false;
            return getClients(typeClient, currentPage, search, order_by, order_asc);
        },
        enabled: debouncedSearchTerm !== null,
        // placeholderData: false,
        retry: 3
    });

    useEffect(() => {
        if (debouncedSearchTerm === "") {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);

    const handleDeleteClients = async (selectedRows: any[]) => {
        const arraySelectedClients = selectedRows.map((item) => item.id);
        try {
            const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/counterparties/counterparty/`, {
                data: {
                    counterparty_ids: arraySelectedClients
                }
            });
            if (response.status === 204) {
                dispatch(closeModal());
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                refetch();
            }
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleAddBlacList = async (selectedRows: any[], comment?: string) => {
        const arraySelectedClients = selectedRows.map((item) => item.id);
        dispatch(closeModal());
        try {
            const response = await axiosApi.post(`${process.env.API_BACKEND}/v1/counterparties/counterparty/black_list/`, {
                counterparty_ids: arraySelectedClients,
                "comment": comment
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                refetch();
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const handleRemoveBlacList = async (selectedRows: any[]) => {
        const arraySelectedClients = selectedRows.map((item) => item.id);
        dispatch(closeModal());
        try {
            const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/counterparties/counterparty/black_list/`, {
                data: {
                    "counterparty_ids": arraySelectedClients,
                    "comment": ""
                }
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                refetch();
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const handleTabChange = (index: number) => {
        setTypeClient(index);
        if(isFetching || isPending) {
            queryClient.cancelQueries({ queryKey: ['getClients'] });
        }
        handleSelectionChange([]);
        setTab(index);
    };

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    }

    return (
        <>
            <DashboardLayout header={
                <div className="dashboard__header">
                    <div className="dashboard__header-title">Управление клиентами</div>
                    
                    <div className="dashboard__right-menu">
                        {hasSelectedRows ? (
                            <>
                            {typeClient !== 2 ? 
                                (
                                    <Button
                                        requiredPermission="add"
                                        themeType="outline-2" 
                                        onClick={() => {
                                            let localComment = '';
                                            dispatch(openModal({
                                            isOpen: true,
                                            content: (
                                                <div className="blockWithComment">
                                                    Вы уверены, что хотите добавить клиентов:<br />
                                                    <b>
                                                        {tab === 0  ?
                                                            <>
                                                                {selectedRows
                                                                    .map(item => {
                                                                    const contact = item.contact;
                                                                    return [contact.surname, contact.first_name, contact.patronymic]
                                                                        .filter(Boolean)
                                                                        .join(' ');
                                                                    })
                                                                    .filter(Boolean)
                                                                    .join(', ')
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                {selectedRows.map((item, key) => (
                                                                    <div key={key}>
                                                                        {item.additional_data?.documents?.organization_name 
                                                                            ? item.additional_data.documents.organization_name
                                                                            : [item.contact?.surname, item.contact?.first_name, item.contact?.patronymic]
                                                                                .filter(Boolean)
                                                                                .join(' ')
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </b> в черный список?
                                                    <textarea placeholder="Комментарий" onChange={(e) => localComment = e.target.value}/>
                                                </div>
                                            ),
                                            config: {
                                                title: "Вы точно хотите добавить в ЧС?",
                                                btns: [
                                                    {
                                                        onClick: () => handleAddBlacList(selectedRows, localComment),
                                                        title: "Да",
                                                        active: true
                                                    },
                                                    {
                                                        onClick: () => dispatch(closeModal()),
                                                        title: "Нет",
                                                        active: false
                                                    }
                                                ]
                                            }
                                        }))}}
                                    >
                                        Добавить в черный список
                                    </Button>
                                ) : (
                                    <Button
                                        requiredPermission="delete"
                                        themeType="outline-2" 
                                        onClick={() => dispatch(openModal({
                                        isOpen: true,
                                        content: (
                                            <div className="blockWithComment">
                                                Вы уверены, что хотите удалить клиентов:<br />
                                                <b>
                                                        {tab === 0  ?
                                                            <>
                                                                {selectedRows
                                                                    .map(item => {
                                                                    const contact = item.contact;
                                                                    return [contact.surname, contact.first_name, contact.patronymic]
                                                                        .filter(Boolean)
                                                                        .join(' ');
                                                                    })
                                                                    .filter(Boolean)
                                                                    .join(', ')
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                {selectedRows.map((item, key) => (
                                                                    <div key={key}>
                                                                        {item.additional_data?.documents?.organization_name 
                                                                            ? item.additional_data.documents.organization_name
                                                                            : [item.contact?.surname, item.contact?.first_name, item.contact?.patronymic]
                                                                                .filter(Boolean)
                                                                                .join(' ')
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </b>
                                                из черного списка?
                                            </div>
                                        ),
                                        config: {
                                            title: "Удалить из черного списка?",
                                            btns: [
                                                {
                                                    onClick: () => handleRemoveBlacList(selectedRows),
                                                    title: "Да",
                                                    active: true
                                                },
                                                {
                                                    onClick: () => dispatch(closeModal()),
                                                    title: "Нет",
                                                    active: false
                                                }
                                            ]
                                        }
                                        }))}
                                    >
                                        Удалить из черного списка
                                    </Button>
                                )
                                }
                                <Button 
                                    requiredPermission="delete"
                                    themeType="delete" 
                                    onClick={() => {
                                        dispatch(openModal({
                                            isOpen: true,
                                            content: (
                                                <>
                                                    {tab !== 2 ? 
                                                        <>Вы удалите профиль {typeClient === 0 ? "физического лица" : "юридического лица"}<br /></>
                                                    :
                                                        <>Вы удалите профиль клиентов:<br/></>
                                                    }
                                                    <b>
                                                        {tab === 0  ?
                                                            <>
                                                                {selectedRows
                                                                    .map(item => {
                                                                    const contact = item.contact;
                                                                    return [contact.surname, contact.first_name, contact.patronymic]
                                                                        .filter(Boolean)
                                                                        .join(' ');
                                                                    })
                                                                    .filter(Boolean)
                                                                    .join(', ')
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                {selectedRows.map((item, key) => (
                                                                    <div key={key}>
                                                                        {item.additional_data?.documents?.organization_name 
                                                                            ? item.additional_data.documents.organization_name
                                                                            : [item.contact?.surname, item.contact?.first_name, item.contact?.patronymic]
                                                                                .filter(Boolean)
                                                                                .join(' ')
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </b>
                                                </>
                                            ),
                                            config: {
                                                title: "Вы точно хотите удалить?",
                                                btns: [
                                                    {
                                                        onClick: () => handleDeleteClients(selectedRows),
                                                        title: "Да",
                                                        active: true,
                                                        isLoading: loading,
                                                    },
                                                    {
                                                        onClick: () => dispatch(closeModal()),
                                                        title: "Нет",
                                                        active: false
                                                    }
                                                ]
                                            }
                                        }))
                                    }}
                                >
                                    Удалить клиента
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button requiredPermission="save" onClick={() => handleClick(CLIENTSCREATE)}>+ Добавить клиента</Button>
                            </>
                        )}
                    </div>
                </div>
            }>
                <TabsWrapper>
                    <Tabs onTabChange={handleTabChange}>
                        <Tab label="Физическое лицо">
                            <WrapperSearch>
                                <Input 
                                    name="" 
                                    $beforeIcon="search" 
                                    placeholder="Поиск"
                                    onChange={onSearch}
                                />
                            </WrapperSearch>
                            {isLoading ? <Loader /> :
                                <>
                                    {data && <Table 
                                        className="clientsTable" 
                                        data={data.counterparties}
                                        columns={columns} 
                                        columnWidths={['40px', '69px']}
                                        selectedRows={selectedRows}
                                        onSelectionChange={handleSelectionChange}
                                        navigation={CLIENTSPROFILE}
                                        totalPages={data.pagination.total_pages}
                                        page={currentPage}
                                        onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                                        isLoading={isLoading}
                                        isError={isError}
                                        onSortChange={setSorting}
                                    />}
                                </>
                            }
                        </Tab>
                        <Tab label="Юридическое лицо">
                            <WrapperSearch>
                                <Input name="" $beforeIcon="search" placeholder="Поиск" onChange={onSearch} />
                            </WrapperSearch>
                            {isLoading ? <Loader /> :
                                <>
                                    {data && <Table 
                                        className="clientsTable" 
                                        data={data.counterparties}
                                        columns={columnsOrg} 
                                        columnWidths={['40px', '69px']}
                                        selectedRows={selectedRows}
                                        onSelectionChange={handleSelectionChange}
                                        navigation={CLIENTSPROFILE}
                                        totalPages={data.pagination.total_pages}
                                        page={currentPage}
                                        onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                                        isLoading={isLoading}
                                        isError={isError}
                                        onSortChange={setSorting}
                                    />}
                                </>
                            }
                        </Tab>
                        <Tab label="Черный список">
                            <WrapperSearch>
                                <Input name="" $beforeIcon="search" placeholder="Поиск" onChange={onSearch} />
                            </WrapperSearch>
                            {isLoading ? <Loader /> :
                                <>
                                    {data && <Table 
                                        className="clientsTable" 
                                        data={data.counterparties}
                                        columns={columnBlackList} 
                                        columnWidths={['40px', '69px']}
                                        selectedRows={selectedRows}
                                        onSelectionChange={handleSelectionChange}
                                        navigation={CLIENTSPROFILE}
                                        totalPages={data.pagination.total_pages}
                                        page={currentPage}
                                        onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                                        isLoading={isLoading}
                                        isError={isError}
                                        onSortChange={setSorting}
                                        disableSorting={false}
                                        disablePagination={false}
                                        disableSelection={false}
                                    />}
                                </>
                            }
                        </Tab>    
                    </Tabs>
                </TabsWrapper>
            </DashboardLayout>
        </>
    )
}