import { StyledCertificateModal, StyledCreateCertificate } from "./style";
import { Input } from "shared/components/Input"
import { Button } from "shared/components/Button"
import { Icon } from "shared/components/Icons"
import { useEffect, useRef, useState } from "react"
import { WrapperFormProfile } from "shared/styles/GlobalStyle"
import { IPropsCertificate, IPropsResponseCertificate} from "../types/CertificateTypes";
import { useCertificates } from "../model/useSertificates";
import moment from "moment";
import IMask from "imask/holder";
import { useQuery } from "@tanstack/react-query";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "shared/redux/slice/modalSlice";
import { Formik, FormikHelpers } from "formik";
import { validateSchema } from "../model/validateSchema";
import { initialValues } from "../model/initialValues";
import { FormikInput } from "shared/components/FormikInput";
import { ModalCertificates } from "./ModalCertificates";

interface IPropsCertificates {
    counterparty_id: string | undefined;
}

export const Certificates = ({counterparty_id}: IPropsCertificates) => {

    const formikRef = useRef<FormikHelpers<any>>(null);
    const now = new Date();

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [currentSertificate, setCurrentSertificate] = useState<IPropsResponseCertificate | null>(null);

    const [loading, setLoading] = useState<number | null>();
    const { handleCreateCertificate, handleDeleteCertificate, handleGetCertificate } = useCertificates();
    const [newCertificates, setNewCertificates] = useState<IPropsCertificate[]>([]);
    const dispatch = useDispatch();
    
    const {data, isLoading, refetch } = useQuery({
        queryKey: ["getCertificates", counterparty_id],
        queryFn: () => handleGetCertificate(counterparty_id),
        enabled: !!counterparty_id
    });

    const addCertificate = () => {
        setNewCertificates([
            ...newCertificates,
            {
                balance: 0,
                counterparty_id: Number(counterparty_id),
                name: "", 
                expired_at: "",
                isEditable: true,
            },
        ]);
    };

    const removeCertificate = async (id: number | undefined, index: number) => {
        setLoading(id ? id : index);
        try {
            if (id) {
                await handleDeleteCertificate(id);
                refetch();
            } else {
                const updatedCertificates = newCertificates.filter((_, i) => i !== index);
                setNewCertificates(updatedCertificates);
            }
            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));
            dispatch(closeModal())
            setLoading(null);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleSubmit = async (values: any, actions: any) => {
        try {
            const certificateData = {
                ...values,
                counterparty_id: Number(counterparty_id),
            };

            await handleCreateCertificate(certificateData);

            actions.resetForm();
            refetch();

            dispatch(showNotification({
                message: "Сертификат успешно создан",
                type: "success",
            }));
            
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleSaveClick = (e: any) => {
        if (formikRef.current) {
            formikRef.current.submitForm();
            e.preventDefault();
        }
    }

    const openModalDownload = (e: any, certificate: IPropsResponseCertificate) => {
        e.preventDefault();
        setCurrentSertificate(certificate);
        setIsOpenModal(true);
    }

    useEffect(() => {
        if(isOpenModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.removeProperty("overflow");
        }
    }, [isOpenModal])

    const onClose = (isOpenModal: boolean) => {
        setIsOpenModal(isOpenModal)
    } 

    return (
        <>
            <StyledCreateCertificate>
                <WrapperFormProfile className="wrapperSerteficate">
                    <div className="wrapperSerteficateHeader">
                        <div className="title">Сертификаты</div>
                        <div className="editButton" onClick={addCertificate}>Добавить сертификат <div className="icon-add"> <Icon name="plus" /></div></div> 
                    </div>
                    
                    {!isLoading && data && data?.certificates.map((certificate) => (
                        <div key={certificate.id} className="certificateItem">
                            <div className="container">
                                <div className="row">
                                    <Input
                                        name="number"
                                        label="Номер" 
                                        value={certificate.id}
                                        placeholder="Номер"
                                        className="inputForm serteficateNumber"
                                        disabled
                                    />
                                    <Input 
                                        name="company"
                                        label="Компания" 
                                        placeholder="Компания"
                                        value="ООО ХКТ"
                                        disabled
                                        className="inputForm"
                                    />
                                    <Input 
                                        name="user"
                                        label="ФИО сотрудника"
                                        value={`${certificate.user.first_name} ${certificate.user.surname}  ${certificate.user.patronymic}`}
                                        placeholder="ФИО сотрудника"
                                        className="inputForm"
                                        disabled
                                    />
                                    <Input 
                                        name="created_at"
                                        label="Дата выдачи" 
                                        placeholder="Дата выдачи"
                                        value={moment(certificate.created_at).format("DD.MM.YYYY")}
                                        className="inputForm serteficateDate"
                                        disabled
                                    />
                                    <Input 
                                        name="expired_at"
                                        label="Дата действия"
                                        maskConfig={{mask: '00.00.0000'}}
                                        placeholder="Дата действия"
                                        value={moment(certificate.expired_at).format("DD.MM.YYYY")}
                                        className="inputForm serteficateDate"
                                        disabled
                                    />
                                    <Input 
                                        name="balance"
                                        label="Сумма (₽)" 
                                        placeholder="Сумма"
                                        value={`${certificate.balance}`}
                                        className="inputForm serteficateSumm"
                                        disabled
                                    />
                                </div>
                                <div className="row" style={{alignItems: "end"}}>
                                    <Input
                                        name="name"
                                        label="Название сертификата" 
                                        value={certificate.name}
                                        placeholder="Название сертификата"
                                        className="inputForm"
                                        disabled
                                    />
                                    <Button
                                        themeType="outline-2"
                                        loading={loading === certificate.id && true}
                                        bgLoading="#FC3F1D"
                                        style={{ borderColor: "#FC3F1D", background: "#FFF1EF", width: "39px", padding: 0 }}
                                        onClick={() => dispatch(openModal({
                                            isOpen: true,
                                            content: (
                                                <>
                                                    Вы точно хотите удалить сертификат?
                                                </>
                                            ),
                                            config: {
                                                title: "Вы точно хотите удалить?",
                                                btns: [
                                                    {
                                                        onClick: () => removeCertificate(certificate.id, -1),
                                                        title: "Да",
                                                        active: true
                                                    },
                                                    {
                                                        onClick: () => dispatch(closeModal()),
                                                        title: "Нет",
                                                        active: false
                                                    }
                                                ]
                                            }
                                        }))}
                                    >
                                        <Icon name="delete" />
                                    </Button>
                                    <Button onClick={(e) => openModalDownload(e, certificate)} themeType="outline" style={{width: "39px", padding: 0}}>
                                        <Icon name="download" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}

                    {newCertificates.map((certificate: any, index: number) => (
                        <Formik
                            innerRef={formikRef as any}
                            key="certificates"
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validateSchema}
                        >
                            <div key={index} className="certificateItem">
                                <div className="container">
                                    <div className="row">
                                        <FormikInput 
                                            name="company"
                                            label="Компания" 
                                            disabled
                                            className="inputForm"
                                        />
                                        {/* <FormikInput 
                                            name="user"
                                            label="ФИО сотрудника"
                                            className="inputForm"
                                            disabled
                                        /> */}
                                        <FormikInput 
                                            name="created_at"
                                            label="Дата выдачи" 
                                            className="inputForm serteficateDate"
                                            disabled
                                        />
                                        <FormikInput
                                            name="expired_at"
                                            label="Дата действия"
                                            placeholder="00.00.0000"
                                            className="inputForm certificateDate"
                                            required
                                            maskConfig = {{
                                                mask: Date,
                                                pattern: 'd{.}`m{.}`Y',
                                                min: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
                                                blocks: {
                                                    d: {
                                                        mask: IMask.MaskedRange,
                                                        from: 1,
                                                        to: 31,
                                                        maxLength: 2,
                                                    },
                                                    m: {
                                                        mask: IMask.MaskedRange,
                                                        from: 1,
                                                        to: 12,
                                                        maxLength: 2,
                                                    },
                                                    Y: {
                                                        mask: IMask.MaskedRange,
                                                        from: moment().year(),
                                                        to: moment().year() + 50,
                                                        maxLength: 4,
                                                    },
                                                }
                                            }}
                                        />
                                        <FormikInput
                                            name="balance"
                                            label="Сумма"
                                            placeholder="Введите сумму"
                                            required
                                            maskConfig={{
                                                mask: Number
                                            }}
                                            className="inputForm serteficateSumm"
                                            $afterIcon="clearInput"
                                        />
                                    </div>
                                    <div className="row" style={{alignItems: "end"}}>
                                        <FormikInput
                                            name="name"
                                            label="Название сертификата"
                                            placeholder="Введите название"
                                            required
                                            className="inputForm certificateName"
                                        />
                                        <Button onClick={(e) => handleSaveClick(e)} style={{width: "39px"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                                <path d="M13 1.00195L4.42857 11.002L1 7.00195" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Button>
                                        <Button
                                            loading={loading === index && true}
                                            themeType="outline-2"
                                            style={{ borderColor: "#FC3F1D", background: "#FFF1EF", width: "39px", padding: 0 }}
                                            bgLoading="#FC3F1D"
                                            onClick={() => dispatch(openModal({
                                                isOpen: true,
                                                content: (
                                                    <>
                                                        Вы точно хотите удалить сертификат?
                                                    </>
                                                ),
                                                config: {
                                                    title: "Вы точно хотите удалить?",
                                                    btns: [
                                                        {
                                                            onClick: () => removeCertificate(undefined, index),
                                                            title: "Да",
                                                            active: true
                                                        },
                                                        {
                                                            onClick: () => dispatch(closeModal()),
                                                            title: "Нет",
                                                            active: false
                                                        }
                                                    ]
                                                }
                                            }))}
                                        >
                                            <Icon name="delete" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Formik>
                    ))}

                </WrapperFormProfile>
            </StyledCreateCertificate>
            {isOpenModal && currentSertificate && (
                <StyledCertificateModal>
                    <ModalCertificates onClose={onClose} certificate={currentSertificate} />
                </StyledCertificateModal>
            )}
        </>
    )
}