import { DashboardLayout } from 'app/layout'
import { StyledItem, TabsWrapper } from '../clientsProfile/style'
import { Tabs, Tab } from 'shared/components/Tabs'
import { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button } from 'shared/components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { UploadAvatar } from 'shared/containers/UploadAvatar'
import { personalInitialValues } from './initialValues/personalInitialValues'
import { StyledLine } from 'shared/styles/GlobalStyle'
import { BackButton } from 'shared/components/BackButton'
import { GeneralDataInput } from './utils/SubPersonal/GeneralDataInput'
import { DocumentsDataInput } from './utils/SubPersonal/DocumentsDataInput'
import { PlaceDataInput } from './utils/SubPersonal/PlaceDataInput'
import { orgInitialValues } from './initialValues/orgInitialValues'
import { OrgGeneralDataInput } from './utils/SubOrganization/OrgGeneralDataInput'
import { OrgContactsDataInput } from './utils/SubOrganization/OrgContactsDataInput'
import { useDispatch } from 'react-redux'
import { showNotification } from 'shared/redux/slice/notificationSlice'
import { ValidateSchemaPersonal } from './model/validateSchemaPersonal'
import { apiServices } from './api/apiServices'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import { ValidateSchemaOrg } from './model/validateSchemaOrg'
import { useFileUploadUp } from 'shared/hooks/useFileUploadUp'
import { CLIENTSLIST } from 'shared/consts/routingConsts'
import { getDynamicCreationReducerHook } from './model/dynamicCreationReducers'

interface IPropsFile {
    id: number
    name: string
    file?: File
}

export const CreateProfile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    // TODO: discuss
    const useDynamicCreationHandler = getDynamicCreationReducerHook(
        location.state?.dynamicCreationReducerType,
    )
    const dynamicCreationHandler = useDynamicCreationHandler()
    const locationInitPersonStateProps =
        location.state?.initPersonFormData ?? {}
    const locationInitCompanyStateProps =
        location.state?.initCompanyFormData ?? {}

    const defaultActiveTab = location.state?.defaultActiveTab ?? 0

    const personalFormRef = useRef<(() => void) | null>(null)
    const orgFormRef = useRef<(() => void) | null>(null)

    const [activeTab, setActiveTab] = useState(0);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isTabChangeConfirmed, setIsTabChangeConfirmed] = useState(false);
    
    const [photo, setPhoto] = useState<File | null>(null)
    const [files, setFiles] = useState<IPropsFile[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)

    const [currentPhoto, setCurrentPhoto] = useState<string | null>(null);

    const { handleFileUpload } = useFileUploadUp()

    useEffect(() => {
        setActiveTab(defaultActiveTab)
    }, [])

    const handleSaveClick = () => {
        if (activeTab === 0 && personalFormRef.current) {
            personalFormRef.current()
        } else if (activeTab === 1 && orgFormRef.current) {
            orgFormRef.current()
        }
    }

    const handleClick = (to: string) => {
        navigate(to)
    }

    const handlePhotoChange = (file: File) => {
        setPhoto(file);
        setCurrentPhoto(URL.createObjectURL(file));
    }

    const onChangeFile = (files: IPropsFile[]) => {
        setFiles(files)
    }

    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true)
        try {
            if (dynamicCreationHandler) {
                dynamicCreationHandler.beforeCreateAction()
            }
            const { uploadedPhotoInfo, uploadedFilesInfo } =
                await handleFileUpload(
                    photo,
                    files?.map(file => file.file!) || [],
                )
            const profileResponse = await apiServices({
                ...values,
                contact: {
                    ...values.contact,
                    email: values.contact.email || null,
                    phone: values.contact.phone || null,
                },
                profile_photo_hash_name: uploadedPhotoInfo
                    ? uploadedPhotoInfo.hash_name
                    : null,
                document_photos_hash_names:
                    uploadedFilesInfo && files
                        ? uploadedFilesInfo.map((info, index) => ({
                              hash_name: info.hash_name,
                              file_name: files[index].name,
                          }))
                        : [],
                discount: Number(values.discount),
            })

            if (profileResponse.status !== 201) {
                dispatch(
                    showNotification({
                        message: 'Ошибка',
                        type: 'error',
                    }),
                )
                return
            }

            dispatch(
                showNotification({
                    message: 'Успешно',
                    type: 'success',
                }),
            )

            if (dynamicCreationHandler) {
                dynamicCreationHandler.postCreateAction(profileResponse.data)
                actions.setSubmitting(false)
                return
            }

            navigate(`/clients/profile/${profileResponse.data.id}`)

            actions.setSubmitting(false)
        } catch (err) {
            console.error(err)
            actions.setSubmitting(false)
        } finally {
            setLoading(false)
        }
    }

    const handleCancelCreate = () => {
        dispatch(closeModal())
        navigate(CLIENTSLIST)
    }

    useEffect(() => {
        if (!isTabChangeConfirmed) {
            setActiveTab(activeTab);
        }
    }, [isTabChangeConfirmed]);

    const handleTabChange = (newTabIndex: number) => {
        if (isFormFilled) {
            dispatch(
                openModal({
                    isOpen: true,
                    content: (
                        <>
                            Вы точно хотите сменить вкладку? Вы потеряете свои данные
                        </>
                    ),
                    config: {
                        title: 'Вы точно хотите сменить вкладку?',
                        btns: [
                            {
                                onClick: () => {
                                    setIsTabChangeConfirmed(true);
                                    setActiveTab(newTabIndex);
                                    dispatch(closeModal());
                                },
                                title: 'Да',
                                active: true,
                            },
                            {
                                onClick: () => {
                                    setIsTabChangeConfirmed(false);
                                    dispatch(closeModal());
                                },
                                title: 'Нет',
                                active: false,
                            },
                        ],
                    },
                }),
            )
        } else {
            setActiveTab(newTabIndex);
        }
    };

    return (
        <DashboardLayout
            header={
                <div className='dashboard__header'>
                    <StyledItem>
                        <BackButton
                            text='Назад'
                            onClick={() => handleClick(CLIENTSLIST)}
                        />
                        <div className='dashboard__header-title'>
                            Создание профиля
                        </div>
                    </StyledItem>
                    <div className='dashboard__right-menu'>
                        <Button
                            requiredPermission="save"
                            bgLoading='white'
                            loading={loading}
                            onClick={handleSaveClick}
                            disabled={disabled}
                        >
                            Сохранить
                        </Button>
                        <Button
                            requiredPermission="delete"
                            themeType='outline-2'
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        isOpen: true,
                                        content: (
                                            <>
                                                Вы точно хотите отменить
                                                создание клиента?
                                            </>
                                        ),
                                        config: {
                                            title: 'Вы точно хотите отменить?',
                                            btns: [
                                                {
                                                    onClick: () => {
                                                        if (
                                                            dynamicCreationHandler
                                                        ) {
                                                            dispatch(
                                                                closeModal(),
                                                            )
                                                            dynamicCreationHandler.onCancel()
                                                            return
                                                        }
                                                        handleCancelCreate()
                                                    },
                                                    title: 'Да',
                                                    active: true,
                                                },
                                                {
                                                    onClick: () =>
                                                        dispatch(closeModal()),
                                                    title: 'Нет',
                                                    active: false,
                                                },
                                            ],
                                        },
                                    }),
                                )
                            }}
                        >
                            Отменить
                        </Button>
                    </div>
                </div>
            }
        >
            <TabsWrapper>
                <Tabs activeTabIndex={activeTab} onTabChange={handleTabChange} disableAutoTabChange>
                    <Tab label='Физическое лицо'>
                        <Formik
                            key='personalForm'
                            initialValues={{
                                ...personalInitialValues,
                                ...locationInitPersonStateProps,
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={ValidateSchemaPersonal}
                            validateOnChange={true}
                        >
                            {({ submitForm, isValid, dirty }) => {
                                setDisabled(!(isValid && dirty))
                                personalFormRef.current = submitForm;
                                setIsFormFilled(dirty);

                                return (
                                    <Form>
                                        <UploadAvatar
                                            initialPhoto={currentPhoto}
                                            onPhotoChange={handlePhotoChange}
                                        />
                                        <StyledLine margin='20px 0' />

                                        {/* Общие данные */}
                                        <GeneralDataInput />

                                        {/* Документы */}
                                        <DocumentsDataInput
                                            onChangeFile={onChangeFile}
                                        />

                                        {/* Место прописки */}
                                        <PlaceDataInput />
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Tab>
                    <Tab label='Юридическое лицо'>
                        <Formik
                            key='orgForm'
                            initialValues={{
                                ...orgInitialValues,
                                ...locationInitCompanyStateProps,
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={ValidateSchemaOrg}
                        >
                            {({ submitForm, isValid, dirty }) => {
                                setDisabled(!(isValid && dirty))
                                orgFormRef.current = submitForm
                                setIsFormFilled(dirty);
                                return (
                                    <Form>
                                        <UploadAvatar
                                            initialPhoto={null}
                                            onPhotoChange={handlePhotoChange}
                                        />
                                        <StyledLine margin='20px 0' />

                                        {/* Общие данные */}
                                        <OrgGeneralDataInput />

                                        {/* Контактные данные */}
                                        <OrgContactsDataInput />
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Tab>
                </Tabs>
            </TabsWrapper>
        </DashboardLayout>
    )
}
