import React from 'react'

import { useBookingFormDateTimeHelper } from 'features/Booking/model/hooks/useBookingFormDateTimeHelper'
import { useBookingFormDateSettings } from 'features/Booking/model/hooks'
import { OrderBooking } from 'entities/orders/model'
import { DiscountInputContainer } from 'entities/bookings/ui'
import { Autocomplete } from 'shared/components/Autocomplete'
import { Input } from 'shared/components/Input'
import { DateRangePickerInput } from 'shared/components/DateRangePickerInput/DateRangePickerInput'
import { TimeRangePickerInput } from 'shared/components/TimeRangePickerInput/TimeRangePickerInput'

interface BookingFormDateSettingsProps {
    isLoading: boolean
    isPreview: boolean
    activeBooking: OrderBooking | null
}

export const BookingFormDateSettings = ({
    isLoading,
    isPreview,
    activeBooking,
}: BookingFormDateSettingsProps) => {
    const {
        currentAreasOptions,
        areasOptions,
        membersCount,
        comment,
        onEditMembersCount,
        onEditComment,
        onEditArea,
        onAbsolutDiscountChange,
        onPercentDiscountChange,
    } = useBookingFormDateSettings(activeBooking)

    const {
        isDisableDateEditing,
        start,
        end,
        onEditStartEndDate,
        onEditStartEndTime,
        customShouldDisableHour,
        customShouldDisableMinutes,
    } = useBookingFormDateTimeHelper(activeBooking)

    return (
        <div className='row'>
            <Autocomplete
                label='Помещение'
                placeholder='Выберите помещение'
                onChange={onEditArea}
                value={currentAreasOptions}
                options={areasOptions}
                required
                disabled={isLoading || isPreview}
            />
            <DiscountInputContainer
                absolutDiscount={activeBooking?.areaAbsolutDiscount ?? null}
                percentDiscount={activeBooking?.areaPercentDiscount ?? null}
                onAbsolutDiscountChange={onAbsolutDiscountChange}
                onPercentDiscountChange={onPercentDiscountChange}
                disabled={isLoading || isPreview}
            />
            <Input
                disabled={isLoading || isPreview}
                name='membersCount'
                type='text'
                placeholder='0'
                onChange={onEditMembersCount}
                value={membersCount ?? ''}
                label='Количество человек'
            />
            <DateRangePickerInput
                label='Дата начала/конца'
                minDate={new Date()}
                disabled={isLoading || isDisableDateEditing || isPreview}
                isLoading={isLoading}
                value={start && end ? [start, end] : null}
                onChange={onEditStartEndDate}
            />
            <TimeRangePickerInput
                label='Время начала/конца'
                minTime={new Date()}
                disabled={isLoading || isDisableDateEditing || isPreview}
                isLoading={isLoading}
                value={start && end ? [start, end] : null}
                onChange={onEditStartEndTime}
                customShouldDisableHour={customShouldDisableHour}
                customShouldDisableMinutes={customShouldDisableMinutes}
                hideMinutes={(minute) => minute % 30 !== 0}
            />
            <Input
                disabled={isLoading || isPreview}
                name='comment'
                placeholder='Комментарий'
                onChange={onEditComment}
                value={comment ?? ''}
                label='Комментарий'
            />
        </div>
    )
}
