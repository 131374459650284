import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
    name: Yup.string().required().min(3).max(100),
    inn: Yup.number().required(),
    kpp: Yup.number().required(),
    ceo: Yup.string().required().min(3).max(100),
    legal_address: Yup.string().required().min(3).max(100),
});

export const validateSchemaRoles = Yup.object().shape({
    name: Yup.string().required()
});