import { axiosApi } from "shared/api/axios";
import { showNotification } from "shared/redux/slice/notificationSlice";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;
}

export const patchOrg = async (
    values: object,
    refetch: () => void,
    setIsEditable: any,
    setEditButtonText: any,
    dispatch: any,
    id: any,
): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.patch(`${process.env.API_BACKEND}/v1/organizations/organization/${id}`, values);

        refetch();
        setIsEditable(false);
        setEditButtonText("Редактировать");
        dispatch(showNotification({message: "Успешно!", type: "success"}))

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        setIsEditable(true);
        setEditButtonText("Сохранить");
        console.error(error);
        throw error;
    }
}