import { OrdersApi } from 'entities/orders/api'
import { useDispatch } from 'react-redux'
import { CounterpartiesApi } from 'entities/counterparties/api'
import { CounterpartyType } from 'entities/counterparties/model'
import {
    setActiveBookingId,
    setBookingCounterparty,
    setBookings,
    setIsCompany,
    setOrderEditStateInitialising,
    setOrderTotalPrice,
    setOriginalOrder,
} from '../slices'

export const useFillStore = () => {
    const dispatch = useDispatch()

    const updateBookings = async (
        orderId: number | null,
        bookingIdToActive?: number | null,
        skipAutoSet?: boolean,
    ) => {
        if (!orderId) {
            return
        }

        dispatch(setOrderEditStateInitialising({ isInitialising: true }))

        const order = await OrdersApi.getDetailOrder(orderId)
        const counterparty = await CounterpartiesApi.getCounterpartyById(
            order.counterpartyId,
        )
        dispatch(
            setIsCompany({
                isCompany: counterparty.type === CounterpartyType.Company,
            }),
        )
        dispatch(setBookingCounterparty({ counterparty }))
        dispatch(setBookings({ bookings: order.bookings }))
        if (!skipAutoSet) {
            dispatch(
                setActiveBookingId({
                    bookingId: bookingIdToActive ?? order.bookings?.[0]?.id,
                }),
            )
        }
        dispatch(setOriginalOrder({ order }))
        dispatch(setOrderTotalPrice({ price: order.totalPrice }))

        dispatch(setOrderEditStateInitialising({ isInitialising: false }))
    }

    return updateBookings
}
