import {
    StyledFooterColumn,
    StyledTotalAmount,
} from 'features/OrderPreview/ui/OrderTotalPricePreview/styles'
import {
    companyRefundWayOptions,
    refundWayOptions,
    useOrderRefundController,
} from 'features/Booking/model/hooks'
import { Flex } from 'shared/components/Flex'
import { Button } from 'shared/components/Button'
import { Input } from 'shared/components/Input'

import { StyledFooterContainer } from './styles'
import { RefundItem } from './RefundItem'
import { Autocomplete } from 'shared/components/Autocomplete'

export const RefundOrder = () => {
    const {
        totalPrice,
        isCompany,
        selectedBookings,
        originalOrder,
        currentRefundWay,

        setCurrentRefundWay,
        onChangeNomenclaturesCount,
        getNomenclaturesCount,
        isBookingChecked,
        isNomenclatureChecked,
        refund,
        fullRefund,
        onChangeBookingsToRefund,
        onChangeBookingsAreaCountToRefund,
        getAreaCount,
    } = useOrderRefundController()

    if (!originalOrder) {
        return <>Заказ не найден</>
    }

    return (
        <StyledFooterContainer>
            <Flex flexDirection='column' gap={5}>
                {originalOrder.bookings.map((booking, index) => (
                    <RefundItem
                        booking={booking}
                        key={index}
                        onChangeBookingsToRefund={onChangeBookingsToRefund}
                        isBookingChecked={isBookingChecked}
                        isNomenclatureChecked={isNomenclatureChecked}
                        onChangeNomenclaturesCount={onChangeNomenclaturesCount}
                        getNomenclaturesCount={getNomenclaturesCount}
                        onChangeBookingsAreaCountToRefund={
                            onChangeBookingsAreaCountToRefund
                        }
                        getAreaCount={getAreaCount}
                    />
                ))}
                <Autocomplete
                    value={currentRefundWay}
                    onChange={setCurrentRefundWay}
                    options={
                        isCompany ? companyRefundWayOptions : refundWayOptions
                    }
                    label='Способ возврата'
                    placeholder='Выберете способ возврата'
                    required
                />
            </Flex>

            <StyledFooterColumn>
                <StyledTotalAmount mode='total'>
                    Итого к возврату
                </StyledTotalAmount>
                <Input value={totalPrice} disabled />
            </StyledFooterColumn>
            <Button
                disabled={selectedBookings?.length < 1 || !currentRefundWay}
                onClick={refund}
            >
                Оформить возврат
            </Button>
            <Button disabled={!currentRefundWay} onClick={fullRefund}>
                Оформить полный возврат
            </Button>
        </StyledFooterContainer>
    )
}
