import * as Yup from 'yup';

export const ValidateSchemaOrg = Yup.object().shape({
    phone: Yup.string().required().matches(/^\+7-\d{3}-\d{3}-\d{2}-\d{2}$/),
    email: Yup.string().email().required().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
    additional_data: Yup.object().shape({
        documents: Yup.object().shape({
            organization_name: Yup.string().required()
        }),
    }),
    contact: Yup.object().shape({
        first_name: Yup.string().min(2).max(20).required(),
        surname: Yup.string().min(2).max(20).required(),
        email: Yup.string().email().required().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
    }),
});