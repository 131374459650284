import React, { FC } from 'react'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'

import {
    Payment,
    PaymentMethodLabels,
    PaymentMethodName,
    PaymentStatus,
} from 'entities/payments/model'
import { Flex } from 'shared/components/Flex'
import { Label } from 'shared/components/Input/style'
import { Accordion } from 'shared/components/Accordion'

import { StyledTextStatus, Text } from './styles'

type PaymentCallbacks = {
    [key in PaymentMethodName]?: (payment: Payment) => void
}

interface PaymentPreviewProps {
    payments: Payment[]
    redirectUrl?: string
    paymentCallbacks?: PaymentCallbacks
    isOpenByDefault?: boolean
    allowPayments?: boolean
}

export const PaymentsPreview: FC<PaymentPreviewProps> = ({
    payments,
    redirectUrl,
    paymentCallbacks,
    isOpenByDefault,
    allowPayments,
}) => (
    <Accordion
        title='История транзакций'
        isSecondaryTitle
        isOpenByDefault={isOpenByDefault}
    >
        <Flex flexDirection='column' gap={10}>
            {payments.map(payment => (
                <Flex key={payment.id} justifyContent='space-between'>
                    <Flex flexDirection='column' flex={1}>
                        <Label>Дата транзакции</Label>
                        <Text>
                            {moment(payment.createdAt).format(
                                'DD.MM.YYYY HH:mm',
                            )}
                        </Text>
                    </Flex>
                    <Flex flexDirection='column' flex={1}>
                        <Label>Сумма</Label>
                        {allowPayments &&
                        payment.method.type === PaymentMethodName.Online &&
                        payment.status === PaymentStatus.Pending ? (
                            <a
                                style={{
                                    fontSize: 14,
                                }}
                                target='_blank'
                                rel='noopener noreferrer'
                                href={payment.paymentUrl}
                                color='#34BD93'
                            >
                                Оплатить ({PaymentMethodLabels?.[payment.method.type]})
                            </a>
                        ) : (
                            <StyledTextStatus
                                status={payment.status}
                                onClick={() =>
                                    payment.status === PaymentStatus.Pending &&
                                    paymentCallbacks?.[
                                        payment.method.type
                                    ]?.call(null, payment)
                                }
                                iscallback={
                                    payment.status === PaymentStatus.Pending &&
                                    !!paymentCallbacks?.[payment.method.type]
                                }
                            >
                                ₽{payment.amount},{' '}
                                {PaymentMethodLabels?.[payment.method.type]}
                            </StyledTextStatus>
                        )}
                    </Flex>
                </Flex>
            ))}
            {redirectUrl && (
                <Link to={redirectUrl} target='_blank'>
                    Открыть страницу оплаты
                </Link>
            )}
        </Flex>
    </Accordion>
)
