import { useQuery } from "@tanstack/react-query"
import { Formik } from "formik"
import { useParams } from "react-router-dom";
import { WrapperFormRow } from "shared/components/WrapperFormRow"
import { getBooking } from "../api/getBooking";
import { Loader } from "shared/components/Loader";
import { getInitialValues } from "../model/getInitialValues";
import { GeneralFormData } from "../model/formData";
import { StyledLine } from "shared/styles/GlobalStyle";
import { StyledChips, StyledTabsBooking } from "./style";
import { Tab, Tabs } from "shared/components/Tabs";
import { Input } from "shared/components/Input";
import moment from "moment";
import { WrapperOrderTable } from "features/OrdersTable/ui/style";
import { Table } from "shared/components/Table";
import { columns } from "../model/columns";

export const BookingsProfile = () => {
    const {id} = useParams();
    
    const {isLoading, data, isError } = useQuery({
        queryKey: ["getBooking", id],
        queryFn: () => getBooking(id),
        enabled: !!id,
        refetchOnWindowFocus: false
    });

    if(isLoading) {
        return <Loader />
    }

    return (
        <>
            {data &&
                <Formik
                    initialValues={getInitialValues(data)}
                    onSubmit={(values) => console.log(values)}
                    enableReinitialize
                >
                    <>
                        <WrapperFormRow 
                            title="1.Общие данные"
                            formData={GeneralFormData}
                        />
                        <StyledLine margin="0 0 10px 0" />
                        {data.bookings &&
                            <>
                                <StyledTabsBooking>
                                    <div className="title">2.Данные по бронированию</div>
                                    <Tabs>
                                        {(data.bookings || []).map((item, index) => (
                                            <Tab key={index} label={item.area.name}>
                                                <div className="container">
                                                    <div className="row">
                                                        <Input 
                                                            label="Помещение"
                                                            value={item.area.name}
                                                            disabled
                                                        />
                                                        <Input 
                                                            label="Количество человек"
                                                            value={item.members_count as any}
                                                            disabled
                                                        />
                                                        <Input 
                                                            label="Сумма заказа"
                                                            value={item.total_price}
                                                            disabled
                                                        />
                                                        <Input 
                                                            label="Дата и время"
                                                            value={`${moment(item.start_time).format("DD.MM.YYYY HH:MM")} - ${moment(item.end_time).format("DD.MM.YYYY HH:MM")}`}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <Input 
                                                            label="Комментарий"
                                                            value={item.comment}
                                                            disabled
                                                        />
                                                        <div className="nomenclatureBlock">
                                                            <div className="title">Дополнительное оборудование</div>
                                                            <div className="chips">
                                                                {item.nomenclatures?.map((item, index) => (
                                                                    <StyledChips key={index}>
                                                                        <div className="titleChips">{item.name}</div>
                                                                        <div className="countChips">(x{item.count})</div>
                                                                        {item.percent_discount && <div className="discountChips">({item.percent_discount}%)</div>}
                                                                        {item.total_price_with_discount && <div className="totalPriceDiscountChips">(₽{item.total_price_with_discount})</div>}
                                                                        {item.total_price && <div className="discountChips">(₽{item.total_price})</div>}
                                                                    </StyledChips>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </StyledTabsBooking>

                                <StyledLine margin="20px 0" />
                            </>
                        }
                        {!isLoading && data && (
                            <StyledTabsBooking>
                                <div className="title">3. История транзакций</div>
                                <WrapperOrderTable>
                                    <Table 
                                        className="clientsTable"
                                        data={data.payments}
                                        columns={columns} 
                                        columnWidths={['100px']}
                                        isLoading={isLoading}
                                        isError={isError}
                                        disablePagination={true}
                                        disableSelection={true}
                                        disableSorting={true}
                                    />
                                </WrapperOrderTable>
                            </StyledTabsBooking>
                        )}
                    </>
                </Formik>
            }
        </>
    )
}
