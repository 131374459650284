import styled from "styled-components";

export const StyledWrapperAvatar = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    .avatarPhoto {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        overflow: hidden;
        background-color: #E1E4EA;
        flex: 0 70px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .avatarContent {
        &__name {
            margin-bottom: 5px;
            color: #282A42;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            word-break: break-all;
        }
        &__jobs {
            color: #8181A5;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
`