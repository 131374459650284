export const GeneralFormData = [
    [
        {
            name: "id",
            label: "Номер заказа",
            type: "text",
            disabled: true,
        },
        {
            name: "counterparty.full_name",
            label: "Заказчик",
            type: "text",
            disabled: true,
        },
        {
            name: "created_at",
            label: "Дата создания",
            type: "text",
            disabled: true,
        },
    ],
    [
        {
            name: "counterparty.phone",
            label: "Телефон",
            type: "text",
            disabled: true,
        },
        {
            name: "counterparty.email",
            label: "Почта",
            type: "text",
            disabled: true,
        }
    ]
];

export const PaymentFormData = [
    [
        {
            name: "total_amount_paid",
            label: "Всего оплачено",
            type: "text",
            value: "",
            disabled: true,
        },
        {
            name: "total_price",
            label: "Сумма заказа",
            type: "text",
            disabled: true,
        },
        {
            name: "total_price_with_discount",
            label: "Сумма заказа с учетом скидки",
            type: "text",
            disabled: true,
        }
    ]
]