import styled, { css } from 'styled-components'

export const StyledFooterContainer = styled.div`
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`

const StyledDescription = css`
    font-size: 16px;
    text-align: left;
`

export const StyledDescriptionItem = styled.span`
    ${StyledDescription};

    color: #8181a5;
`

export const StyledFooterRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`
