import { axiosApi } from "shared/api/axios";
import { IPropsNotifications } from "../types";

export const getNotifications = async (): Promise<IPropsNotifications> => {
    try {
        const response = await axiosApi.get<IPropsNotifications>(`${process.env.API_BACKEND}/v1/notifications/notification/`);
        const data = response.data;

        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};