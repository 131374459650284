import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
    ActiveBookingFormTab,
    BookingMode,
    BookingState,
    BookingStep,
    OrderViewMode,
} from './types'
import {
    addBookingAction,
    addEmptyBookingAction,
    addNomenclatureToBookingAction,
    clearBookingStateAction,
    editBookingAction,
    removeBookingAction,
    removeNomenclatureToBookingAction,
    replaceBookingTempClientIdToOriginIdAction,
    setActiveBookingIdAction,
    setBookingActiveTabAction,
    setBookingCounterpartyAction,
    setBookingCurrentStepAction,
    setBookingFormModeAction,
    setBookingFormOrderViewModeAction,
    setBookingIdToEditAction,
    setBookingOrderIdAction,
    setBookingsAction,
    setCommentToBookingAction,
    setCurrentCalendarEventsAction,
    setIsCompanyAction,
    setIsDirtyAction,
    setMembersCountToBookingAction,
    setOrderEditStateInitialisingAction,
    setOrderTotalPriceAction,
    setOriginalOrderAction,
    setTodayAction,
    setViewAction,
} from './actions'
import { ViewMode } from '../../../BookingCalendarController/model'

export const initialState: BookingState = {
    currentStep: BookingStep.GeneralInfo,
    mode: BookingMode.Create,
    orderViewMode: OrderViewMode.Preview,
    activeBookingId: null,
    bookingIdToEdit: null,

    originalOrder: null,
    bookingActiveTab: ActiveBookingFormTab.Booking,
    isEditStateInitialising: false,
    today: moment().toDate().toISOString(),
    view: ViewMode.Day,
    isDirty: false,
    isCompany: null,

    orderId: null,
    counterparty: null,
    totalPrice: null,

    bookings: [],
    currentCalendarEvents: [],
}

export const bookingSidebarFormSlice = createSlice({
    name: 'bookingSidebarForm',
    initialState,
    reducers: {
        setActiveBookingId: setActiveBookingIdAction,
        setBookingIdToEdit: setBookingIdToEditAction,

        setBookingCurrentStep: setBookingCurrentStepAction,
        clearBookingState: clearBookingStateAction,
        setBookingActiveTab: setBookingActiveTabAction,
        setOrderEditStateInitialising: setOrderEditStateInitialisingAction,

        addBooking: addBookingAction,
        addEmptyBooking: addEmptyBookingAction,
        removeBooking: removeBookingAction,
        editBooking: editBookingAction,

        setBookingCounterparty: setBookingCounterpartyAction,
        setBookingOrderId: setBookingOrderIdAction,
        setMembersCountToBooking: setMembersCountToBookingAction,
        setCommentToBooking: setCommentToBookingAction,
        replaceBookingTempClientIdToOriginId:
            replaceBookingTempClientIdToOriginIdAction,
        addNomenclatureToBooking: addNomenclatureToBookingAction,
        removeNomenclatureToBooking: removeNomenclatureToBookingAction,
        setOrderTotalPrice: setOrderTotalPriceAction,

        setBookingFormMode: setBookingFormModeAction,
        setBookingFormOrderViewMode: setBookingFormOrderViewModeAction,

        setBookings: setBookingsAction,
        setOriginalOrder: setOriginalOrderAction,

        setToday: setTodayAction,
        setView: setViewAction,
        setIsDirty: setIsDirtyAction,
        setIsCompany: setIsCompanyAction,

        setCurrentCalendarEvents: setCurrentCalendarEventsAction,
    },
})

export const {
    setActiveBookingId,
    setBookingIdToEdit,
    setBookingCurrentStep,
    clearBookingState,
    setBookingActiveTab,
    setOrderEditStateInitialising,

    addBooking,
    addEmptyBooking,
    removeBooking,
    editBooking,

    setBookingCounterparty,
    setBookingOrderId,
    setMembersCountToBooking,
    setCommentToBooking,
    replaceBookingTempClientIdToOriginId,
    addNomenclatureToBooking,
    removeNomenclatureToBooking,
    setOrderTotalPrice,

    setBookingFormMode,
    setBookingFormOrderViewMode,
    setBookings,
    setOriginalOrder,

    setToday,
    setView,
    setIsDirty,
    setIsCompany,

    setCurrentCalendarEvents,
} = bookingSidebarFormSlice.actions

export default bookingSidebarFormSlice.reducer
