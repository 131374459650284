import { IPropsCard } from "../types";

export const trafic = [
    {
        name: "Google",
        procent: "13"
    },
    {
        name: "YouTube",
        procent: "13"
    },
    {
        name: "Instagram",
        procent: "13"
    },
    {
        name: "Pinterest",
        procent: "13"
    },
    {
        name: "Facebook",
        procent: "13"
    },
    {
        name: "Twitter",
        procent: "13"
    },
    {
        name: "Google",
        procent: "13"
    },
];

export const card: IPropsCard[] = [ 
    { 
        icon: "ruble", 
        title: "Общая прибыль", 
        keyData: "total_revenue",
        showCurrency: true,
    }, 
    { 
        icon: "chart", 
        title: "Количество заказов", 
        keyData: "total_day_orders" 
    }, 
    { 
        icon: "average", 
        title: "Средний чек по заказам", 
        keyData: "total_average_check",
        showCurrency: true,
    }, 
    { 
        icon: "clients", 
        title: "Всего клиентов", 
        keyData: "unique_clients",
    }, 
    { 
        icon: "newClients", 
        title: "Новых клиентов", 
        keyData: "new_clients",
    }, 
];

export const dropdownPeriod = ["Сегодня", "Неделя", "Месяц"];


interface TableColumn {
    key: "display_name" | "order_count" | "total_spend";
    title: string;
}


export const clientsHeader: TableColumn[] = [
    {
        title: "Имя",
        key: "display_name"
    },
    {
        title: "Количество заказов",
        key: "order_count"
    },
    {
        title: "Всего потрачено",
        key: "total_spend"
    },
];

export const areaHeader: TableColumn[] = [
    {
        title: "Зал",
        key: "display_name"
    },
    {
        title: "Оборудование",
        key: "order_count"
    },
    {
        title: "Освободится",
        key: "total_spend"
    },
];

export const clientsList = [
    { name: "Иванов Иван Иванович", orders: 32, total: 1250256 },
    { name: "Петров Петр Петрович", orders: 32, total: 950256 },
    { name: "Сидоров Сидор Сидорович", orders: 32, total: 750256 },
    { name: "Григорьев Григорий Григорьевич", orders: 32, total: 550256 },
    { name: "Алексеев Алексей Алексеевич", orders: 32, total: 100256 },
];

export const areasList = [
    { name: "Название зала", orders: "Лампа", time: "Сегодня, 16:30" },
    { name: "Название зала", orders: "Прожектор", time: "Завтра, 16:30" },
    { name: "Название зала", orders: "Подставка", time: "14.12.2024, 12:30" },
    { name: "Название зала", orders: "Фотоаппарат", time: "Сегодня, 21:30" },
    { name: "Название зала", orders: "Стул", time: "Завтра, 10:30" },
];