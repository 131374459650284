import { IPropsTopClients } from "pages/dashboard/types";
import { TableContainer, TableHeader, TableTitle, TableBody } from "./style"

interface TableColumn {
    key: "display_name" | "order_count" | "total_spend";
    title: string;
}

interface IPropsTableDashboard {
    title: string;
    headerRow: TableColumn[];
    bodyRow?: IPropsTopClients[];
}


export const TableDashboard = ({ headerRow, bodyRow, title }: IPropsTableDashboard) => {
    return (
        <TableContainer>
            <TableTitle>{title}</TableTitle>
            <TableHeader>
                {headerRow.map((column, index) => (
                    <div key={index}>{column.title}</div>
                ))}
            </TableHeader>
            <TableBody>
                {bodyRow && bodyRow.map((row, index) => (
                    <div className="TableDasboardRow" key={index}>
                        {headerRow.map((column, colIndex) => (
                            <div key={colIndex}>
                                {/* {typeof row[column.key] === "number" ? `${row[column.key].toLocaleString()}` : row[column.key]} */}
                                {row[column.key] as keyof IPropsTopClients}
                            </div>
                        ))}
                    </div>
                ))}
            </TableBody>
        </TableContainer>
    );
};