import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/redux/hooks';
import { WrapperLoader } from './style';
import { useEffect } from 'react';
import { PermissionsProviders } from 'app/providers/permissionsProviders';
import { checkAuth, logout } from 'features/auth/model/authSlice';

export const PrivateRoute = () => {
    const { isAuth, isAuthInProgress } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if (!token) {
            dispatch(logout());
            navigate('/auth/sign-in');
            return;
        }

        dispatch(checkAuth());
    }, [dispatch, navigate]);

    if (isAuthInProgress) {
        return (
            <WrapperLoader>
                <div className="loader"></div>
            </WrapperLoader>
        );
    }

    return isAuth ? (
        <PermissionsProviders>
            <Outlet />
        </PermissionsProviders>
    ) : null;
};