import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { GetCreatePaymentDto } from 'entities/payments/model/dtoTypes'
import { CreateCertificatePaymentRequest } from 'entities/payments/model'
import { adaptPaymentDTO } from 'entities/payments/model/adapters/adaptPaymentDTO'
import { adaptCreateCertificatePaymentRequest } from 'entities/payments/model/adapters/adaptCreateCertificatePaymentRequest'

export const createCertificatePayment = async (
    body: CreateCertificatePaymentRequest,
) => {
    try {
        const response = await axiosApi.post<GetCreatePaymentDto>(
            PaymentEndpoint.CreateCertificatePayment,
            adaptCreateCertificatePaymentRequest(body),
        )
        return adaptPaymentDTO(response.data)
    } catch (err: any) {
        throw new Error(
            `Ошибка создания платежа: ${err?.response?.data?.detail}`,
        )
    }
}
