import { axiosApi } from "shared/api/axios";
import { IPropsProfile } from "../types";

export const getProfile = async (): Promise<IPropsProfile> => {
    try {
        const response = await axiosApi.get<IPropsProfile>(`${process.env.API_BACKEND}/v1/users/user/info/me`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
