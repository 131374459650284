import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { GetCreatePaymentDto } from 'entities/payments/model/dtoTypes'
import { CreateAccountPaymentRequest } from 'entities/payments/model'
import { adaptPaymentDTO } from 'entities/payments/model/adapters/adaptPaymentDTO'
import { adaptCreateAccountPaymentRequest } from 'entities/payments/model/adapters/adaptCreateAccountPaymentRequest'

export const createAccountPayment = async (
    body: CreateAccountPaymentRequest,
) => {
    try {
        const response = await axiosApi.post<GetCreatePaymentDto>(
            PaymentEndpoint.CreateAccountPayment,
            adaptCreateAccountPaymentRequest(body),
        )
        return adaptPaymentDTO(response.data)
    } catch (err: any) {
        throw new Error(
            `Ошибка создания платежа: ${err?.response?.data?.detail}`,
        )
    }
}
