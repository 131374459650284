import React, { FC, SyntheticEvent } from 'react'

import { InputContainer, Label } from 'shared/components/Input/style'
import { Locale } from 'shared/components/DatePickerInput/DatePickerInput'
import { StyledTimeRangePicker } from 'shared/components/TimeRangePickerInput/style'
import './style.css'
import moment from 'moment'

export type CalendarEvent = SyntheticEvent<Element, Event>
interface TimeRangePickerInputProps {
    value?: [Date, Date] | null
    onChange?: (date: [Date, Date] | null, e: CalendarEvent) => void
    placeholder?: string
    label?: string
    isLoading?: boolean
    disabled?: boolean
    required?: boolean
    editable?: boolean
    minTime?: Date
    maxTime?: Date
    customShouldDisableHour?: (
        hour: number,
        currentDate: Date,
        isStart: boolean,
        isEnd: boolean,
    ) => boolean
    customShouldDisableMinutes?: (minute: number, currentValue: Date) => boolean
    hideMinutes?: (minute: number, currentValue: Date) => boolean
}

export const isDisabledTimeHours = (
    hours: number,
    start?: Date | null,
    minTime?: Date,
    maxTime?: Date,
) => {
    if (start && moment(start).isAfter(moment())) {
        return false
    }

    const currentHour = moment().hour(hours).minute(0).second(0)

    if (minTime && currentHour.isBefore(moment(minTime).startOf('hour'))) {
        return true
    }

    return !!(maxTime && currentHour.isAfter(moment(maxTime).endOf('hour')))
}

export const TimeRangePickerInput: FC<TimeRangePickerInputProps> = ({
    value,
    onChange,
    placeholder,
    label,
    isLoading,
    disabled,
    required,
    editable,
    minTime,
    maxTime,
    customShouldDisableHour,
    customShouldDisableMinutes,
    hideMinutes,
}) => {
    return (
        <InputContainer>
            {label && (
                <Label>
                    {required && <span className='required'>*</span>}
                    {label}
                </Label>
            )}
            <StyledTimeRangePicker
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                size='lg'
                showHeader={false}
                format='HH:mm'
                loading={isLoading}
                disabled={disabled}
                editable={editable}
                hideMinutes={hideMinutes}
                onEntering={() => {}}
                shouldDisableHour={(hour: number, currentDate: Date) =>
                    customShouldDisableHour
                        ? customShouldDisableHour(
                              hour,
                              currentDate,
                              value?.[0]
                                  ? moment(currentDate).isSame(
                                        moment(value?.[0]),
                                    )
                                  : false,
                              value?.[1]
                                  ? moment(currentDate).isSame(
                                        moment(value?.[1]),
                                    )
                                  : false,
                          ) ||
                          isDisabledTimeHours(
                              hour,
                              value?.[0],
                              minTime,
                              maxTime,
                          )
                        : isDisabledTimeHours(
                              hour,
                              value?.[0],
                              minTime,
                              maxTime,
                          )
                }
                shouldDisableMinute={(minute: number, currentValue: Date) =>
                    customShouldDisableMinutes &&
                    customShouldDisableMinutes(minute, currentValue)
                }
                shouldDisableSecond
                locale={Locale}
                placement='autoHorizontalEnd'
            />
        </InputContainer>
    )
}
