import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { axiosApi } from "shared/api/axios";
import { BackButton } from "shared/components/BackButton"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { FormikHelpers } from "formik"
import { SETTINGORG } from "shared/consts/routingConsts"
import { StyledItem } from "pages/NomenclatureProfile/style";
import { SettingOrgProfile } from "features/Setting";

export const SettingOrgProfilePages = () => {
    const {id} = useParams();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const handleDeleteProfile = () => {
        axiosApi.delete(`${process.env.API_BACKEND}/v1/organizations/organization/${id}`);
        dispatch(closeModal());
        navigate(SETTINGORG)
    }

    const handleGoBack = () => {
        navigate(SETTINGORG);
    }
    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">Организация</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                {isEditable ? (
                    <>
                        <Button themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                        <Button themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                    </>
                ) : (
                    <>
                        <Button themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                        <Button 
                            themeType="delete" 
                            onClick={() => dispatch(openModal({
                                isOpen: true,
                                content: (
                                    <>
                                        Вы точно хотите удалить организацию?
                                    </>
                                ),
                                config: {
                                    title: "Вы точно хотите удалить?",
                                    btns: [
                                        {
                                            onClick: () => handleDeleteProfile(),
                                            title: "Да",
                                            active: true
                                        },
                                        {
                                            onClick: () => dispatch(closeModal()),
                                            title: "Нет",
                                            active: false
                                        }
                                    ]
                                }
                            }))}
                        >
                            Удалить организацию
                        </Button>

                    </>
                )}
                </div>
            </div>
        }>
            <SettingOrgProfile 
                setDisabled={setDisabled}
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
            />
        </DashboardLayout>
    )
}