import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CounterpartiesApi } from '../../api'
import { QueryKeys } from './constants'
import { Counterparty } from '../index'

export const useGetCounterpartyByIdQuery = (
    id: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const {
        data,
        error,
        isError,
        isLoading,
        isFetching,
        isSuccess,
        refetch,
        status,
    } = useQuery({
        initialData: null,
        queryKey: [QueryKeys.GetCounterpartyByIdQuery, id],
        queryFn: id
            ? () => CounterpartiesApi.getCounterpartyById(id)
            : () => null,
        enabled: !!id,
        ...(options ?? {}),
    })

    return {
        counterparty: data as Counterparty,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isFetching,
        isError,
    }
}
