import { axiosApi } from "shared/api/axios";
import { CounterpartiesResponse } from "../types";

export const getClients = async (
    typeClient: number, 
    currentPage: number, 
    search_param?: string,
    order_by?: any,
    order_asc?: any,
): Promise<CounterpartiesResponse> => {
    try {
        const baseUrl = `${process.env.API_BACKEND}/v1/counterparties/counterparty/?page=${currentPage}&size=10&order_asc=${order_asc}&order_by=${order_by}`;
        const typeFilter = typeClient === 2 ? "&black_list_filter=true" : `&type_filter=${typeClient}`;
        const searchFilter = search_param ? `&search_param=${encodeURIComponent(search_param)}` : "";
        const url = `${baseUrl}${typeFilter}${searchFilter}`;

        const response = await axiosApi.get<CounterpartiesResponse>(url);
        return response.data;
    } catch (err) {
        console.error("Error fetching clients", err);
        return { counterparties: [], pagination: { total_pages: 0, current_page: 0, page_size: 0 } };
    }
};