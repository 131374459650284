import moment from 'moment'
import { useParams } from 'react-router-dom'
import React from 'react'

import { useGetPaymentsQuery } from 'entities/payments/model'

import { useGetDetailOrderQuery } from 'entities/orders/model'
import { Line } from 'shared/components/Line'
import { Flex } from 'shared/components/Flex'

import {
    InvoiceDetailsTable,
    InvoiceHeader,
    InvoiceSubHeader,
    InvoiceDetailsOverview,
    InvoicePaymentsTable,
} from './components'
import { StyledContainer, StyledHeaderTitle } from './components/styles'
import { OrderPaidStatus } from 'features/OrderPreview/ui/components/OrderPaidStatus'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'
import {
    OrderTotalPricePreview,
    PaymentsPreview,
} from 'features/OrderPreview/ui'
import { LoaderPreview } from 'features/OrderPreview/ui'

const REFETCH_INTERVAL = 5_000

export const InvoicePage = () => {
    const { id } = useParams()
    const orderId = Number(id)
    const { detailOrder, isFetching } = useGetDetailOrderQuery(orderId, {
        refetchInterval: 5000,
    })
    const { isSmallDevice, isMediumDevice } = useMediaQuery()

    const { payments, isFetching: isPaymentFetching } = useGetPaymentsQuery(
        undefined,
        { refetchInterval: REFETCH_INTERVAL },
    )

    const currentPayments = payments?.filter(p => p.orderId === orderId) ?? []

    if (!payments.length && (isFetching || isPaymentFetching)) {
        return <LoaderPreview />
    }

    if (!id || !detailOrder) {
        return <div>Заказ не найден</div>
    }

    const createdAt = moment(detailOrder.createdAt).format('DD.MM.YYYY HH:mm')

    return (
        <Flex justifyContent='center' minHeight='100vh'>
            <StyledContainer>
                <InvoiceHeader id={id} createdAt={createdAt} />
                <InvoiceSubHeader />
                <Line />

                <InvoiceDetailsOverview detailOrder={detailOrder} />
                <Flex flexDirection='column' gap={10} flex={1}>
                    <StyledHeaderTitle>Смета</StyledHeaderTitle>
                    {isSmallDevice || isMediumDevice ? (
                        <OrderTotalPricePreview
                            detailOrder={detailOrder}
                            hideStatus
                        />
                    ) : (
                        <InvoiceDetailsTable bookings={detailOrder.bookings} />
                    )}
                </Flex>
                <Flex flexDirection='column' gap={10}>
                    <StyledHeaderTitle>История транзакций</StyledHeaderTitle>
                    {isSmallDevice || isMediumDevice ? (
                        <PaymentsPreview
                            payments={currentPayments}
                            allowPayments
                            isOpenByDefault
                        />
                    ) : (
                        <InvoicePaymentsTable
                            payments={currentPayments ?? []}
                        />
                    )}
                </Flex>
                <Flex justifyContent='end' gap={10}>
                    <OrderPaidStatus
                        total={detailOrder.totalPriceWithDiscount}
                        paid={detailOrder.totalAmountPaid}
                    />
                </Flex>
            </StyledContainer>
        </Flex>
    )
}
