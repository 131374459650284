import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Table } from "shared/components/Table";
import { getEmployees } from "../api/getEmployees";
import { SortingState } from "@tanstack/react-table";
import { columns } from "../model/columns";
import { useDebounce } from "shared/hooks/useDebounce";
import { EMPLOYEESPROFILE } from "shared/consts/routingConsts";
import { useSearchParams } from "react-router-dom";

interface IPropsEmployeesTable {
    onSelectedRowsChange: (selectedRows: number[]) => void;
    search: string;
    selectedRows: any[];
}

export const EmployeesTable = ({
    onSelectedRowsChange,
    search,
    selectedRows
}:IPropsEmployeesTable) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [sorting, setSorting] = useState<SortingState>([]);
    
    const debouncedSearchTerm = useDebounce(search, 1000);

    const currentPage = parseInt(searchParams.get("page") || "1", 10);

    const handleSelectionChange = (newSelectedRows: any[]) => {
        onSelectedRowsChange(newSelectedRows);
    };

    const { isLoading, isError, data, refetch} = useQuery({
        queryKey: ["getEmployees", currentPage, search, sorting],
        queryFn: ({signal}) => {
            const order_by = sorting.length ? sorting[0].id : "created_at";
            const order_asc = sorting.length ? sorting[0].desc === true : false;
            return getEmployees(currentPage, search, order_by, order_asc, signal);
        },
        enabled: debouncedSearchTerm !== null,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (debouncedSearchTerm === "") {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);

    return (
        <>
            {!isLoading && data && (
                <Table 
                    className="clientsTable" 
                    data={data.users}
                    columns={columns} 
                    columnWidths={['40px', '69px']}
                    selectedRows={selectedRows}
                    onSelectionChange={handleSelectionChange}
                    navigation={EMPLOYEESPROFILE}
                    totalPages={data.pagination.total_pages}
                    page={currentPage}
                    onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                    isLoading={isLoading}
                    isError={isError}
                    onSortChange={setSorting}
                />
            )}
        </>
    )
}