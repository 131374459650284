import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { OrdersApi } from 'entities/orders/api'
import { Counterparty } from 'entities/counterparties/model'
import {
    BookingMode,
    BookingStep,
    selectBookingCounterparty,
    selectBookingMode,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    setBookingCounterparty,
    setBookingCurrentStep,
    setBookingOrderId,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'
import { CLIENTSCREATE } from 'shared/consts/routingConsts'

import { DynamicCreationReducersType } from 'pages/clientsCreate/model/dynamicCreationReducers'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { showNotification } from 'shared/redux/slice/notificationSlice'

export const useGeneralInfoController = (isCompany?: boolean) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const orderId = useSelector(selectBookingOrderId)
    const originalOrder = useSelector(selectBookingOriginalOrder)

    const counterparty = useSelector(selectBookingCounterparty)
    const counterpartyId = counterparty?.id ?? null
    const mode = useSelector(selectBookingMode)
    const isCreate = useMemo(() => mode === BookingMode.Create, [mode])
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const [isLoading, setIsLoading] = useState(false)

    const { isOneOfTheBookingsAlreadyCreated } = useIsBookingsAlreadyCreated()

    const isButtonDisabled = !counterpartyId

    const onCounterpartyChange = (option: AutocompleteOption<Counterparty>) => {
        const counterparty = option ? option.payload ?? null : null
        dispatch(setBookingCounterparty({ counterparty }))
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = () => {
        dispatch(
            showNotification({
                message: 'Ошибка',
                type: 'error',
            }),
        )
    }

    const isDirty = counterpartyId !== originalOrder?.counterpartyId

    const onGoNext = async () => {
        try {
            if (!counterpartyId) {
                return
            }

            dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))

            if ((isEdit || isOneOfTheBookingsAlreadyCreated) && orderId) {
                if (!isDirty) {
                    return
                }

                setIsLoading(true)
                await OrdersApi.updateOrder({
                    orderId,
                    counterpartyId: Number(counterpartyId),
                })
                setIsLoading(false)
                showSuccessAlert()
                return
            }

            if (orderId) {
                return
            }

            setIsLoading(true)
            const result = await OrdersApi.createOrder({
                counterpartyId: Number(counterpartyId),
            })
            dispatch(setBookingOrderId({ orderId: result.orderId }))
            showSuccessAlert()
        } catch {
            showErrorAlert()
        } finally {
            setIsLoading(false)
        }
    }

    const createCounterpartyAndChange = ({
        phone,
        organizationName,
        inn,
        name,
        email,
    }: {
        phone?: string
        organizationName?: string
        inn?: string
        name?: string
        email?: string
    }) => {
        const [surname = '', first_name = '', patronymic = ''] = name
            ? name.split(' ')
            : []
        navigate(CLIENTSCREATE, {
            state: {
                initPersonFormData: {
                    phone: phone ?? '',
                    email: email ?? '',
                    contact: {
                        surname,
                        first_name,
                        patronymic,
                    },
                },
                initCompanyFormData: {
                    phone: phone ?? '',
                    additional_data: {
                        documents: {
                            organization_name: organizationName ?? '',
                            inn: inn ?? '',
                        },
                    },
                },
                defaultActiveTab: isCompany ? 1 : 0,
                dynamicCreationReducerType: DynamicCreationReducersType.Booking,
            },
        })
    }

    return {
        isDirty,
        isLoading,
        orderId,
        isCreate,
        isEdit,
        isButtonDisabled,
        isOneOfTheBookingsAlreadyCreated,
        onGoNext,
        onCounterpartyChange,
        createCounterpartyAndChange,
    }
}
