import styled from "styled-components";

export const TableContainer = styled.div`
    padding: 16px;
    border-radius: 8px;
    background-color: #FAFCFF;
    width: 100%;
`;

export const TableTitle = styled.div`
    margin-bottom: 15px;
    color: #1C1C1C;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #DBE3EE;
    padding-left: 12px;
    padding-right: 12px;
    div {
        color: #030229;
        font-size: 12px;
        font-weight: 600;
        line-height: 130%;
    }
`

export const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #030229;
    font-size: 12px;
    line-height: 130%;
    .TableDasboardRow {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 12px;
        border-radius: 8px;
        &:nth-child(even) {
            background-color: #ECF1F9;
        }
    }
`