export const columns = [
    {
        header: "Наименование",
        id: "name",
        cell: (row: any) => {
            const organization_name = row.renderValue();
            return (
                <div className={organization_name.length >= 15 ? "crop" : ""}>
                    {organization_name}
                </div>
            )
        },
        accessorKey: "name"
    },
    {
        header: "ИНН",
        id: "inn",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "inn"
    },
    {
        header: "Юридический адрес",
        id: "legal_address",
        cell: (row: any) => {
            const organization_name = row.renderValue();
            return (
                <div className={organization_name.length >= 15 ? "crop" : ""}>
                    {organization_name}
                </div>
            )
        },
        accessorKey: "legal_address"
    },
]