import { DashboardLayout } from "app/layout"
import { SettingSidebarStyled, SettingWrapper } from "./style"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { sidebardMenuSetting } from "shared/consts/sidebarMenuData"
import { Icon } from "shared/components/Icons"
import { useEffect, useState } from "react"
import { SETTINGNOTIFICATION, SETTINGPROFILE, SETTINGTHEME } from "shared/consts/routingConsts"
import { SettingMenuTypes } from "shared/types/sidebarMenuTypes"

export const SettingPages = () => {

    const [settingPageList, setSettingPageList] = useState<SettingMenuTypes[]>([]);

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/setting') {
            navigate(SETTINGPROFILE)
        }
    }, []);

    useEffect(() => {
            const updatePermissions = () => {
                const permissions = JSON.parse(localStorage.getItem('permissionsUsers') || '{}');

                const permissionsSetting = permissions.pages_permissions.some((item: any) => item.name === "Настройки");

                if(!permissionsSetting) {
                    setSettingPageList([
                        {
                            icon: "settingProfile",
                            title: "Профиль",
                            link: SETTINGPROFILE,
                        },
                        {
                            icon: "settingTheme",
                            title: "Тема",
                            link: SETTINGTHEME
                        },
                        {
                            icon: "settingNotification",
                            title: "Уведомления",
                            link: SETTINGNOTIFICATION
                        },
                    ])
                } else {
                    setSettingPageList(sidebardMenuSetting)
                }
            };
        
            updatePermissions();
    
        }, []);



    return (
        <DashboardLayout>
            <SettingWrapper>
                <SettingSidebarStyled>
                    {settingPageList.map((item, index) => (
                        <NavLink key={index} to={item.link}>
                            <Icon name={item.icon} />
                            {item.title}
                        </NavLink>
                    ))}
                </SettingSidebarStyled>
                <Outlet />
            </SettingWrapper>
        </DashboardLayout>
    )
}