import { useBookingDynamicCreationReducers } from './useBookingDynamicCreationReducers'
import { Counterparty } from 'entities/counterparties/model'

export const DynamicCreationReducersType = {
    Booking: 'booking',
}

export type DynamicCreationReducersType =
    (typeof DynamicCreationReducersType)[keyof typeof DynamicCreationReducersType]

export type DynamicCreationReducerHandler = {
    postCreateAction: (counterparty: Counterparty) => void
    beforeCreateAction: () => void
    onCancel: () => void
}

export type GetDynamicCreationReducerHook =
    () => DynamicCreationReducerHandler | null

export const emptyCall = () => null

export const getDynamicCreationReducerHook = (
    type?: DynamicCreationReducersType,
): GetDynamicCreationReducerHook => {
    if (!type) {
        return emptyCall
    }

    switch (type) {
        case DynamicCreationReducersType.Booking:
            return useBookingDynamicCreationReducers
        default:
            return emptyCall
    }
}
