import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import deepEqual from 'fast-deep-equal'

import { AccountDetails } from 'features/DetailsPayments/ui/DetailsPaymentsModal'
import { useFillStore } from 'features/Booking/model/hooks/useFillStore'
import { DetailsPaymentsModal } from 'features/DetailsPayments/ui'
import { Text } from 'features/Booking/ui/components/Forms/styles'
import {
    Payment,
    PaymentMethodName,
    PaymentMethodType,
    useGetPaymentMethodsQuery,
    useGetPaymentsQuery,
} from 'entities/payments/model'
import { PaymentApi } from 'entities/payments/api'
import { useCertificatesQuery } from 'entities/certificates/model/hooks'
import { AttachmentsApi } from 'entities/orderAttachments/api'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { showNotification } from 'shared/redux/slice/notificationSlice'
import useDownloadFile from 'shared/hooks/useDownloadFile'

import {
    selectBookingCounterparty,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    selectIsCompanyBookingForm,
} from '../slices'

type CertificateOption = AutocompleteOption<{ balance: string }>
type PaymentTypeOption = AutocompleteOption<{ type: PaymentMethodType }>

export const usePaymentFormController = () => {
    const dispatch = useDispatch()
    const downloadFile = useDownloadFile()

    const originalOrder = useSelector(selectBookingOriginalOrder)
    const orderId = useSelector(selectBookingOrderId)
    const counterparty = useSelector(selectBookingCounterparty)
    const counterpartyId = counterparty?.id ?? null
    const isCompany = useSelector(selectIsCompanyBookingForm)
    const updateBookings = useFillStore()

    const [paymentTypeOption, setPaymentTypeOption] =
        useState<PaymentTypeOption>()
    const paymentType = paymentTypeOption?.payload?.type
    const [certificate, setCertificate] = useState<CertificateOption>()

    const [comment, setComment] = useState<string>()
    const [amount, setAmount] = useState<number>()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isPaid = useMemo(
        () =>
            !!originalOrder?.totalAmountPaid &&
            !!originalOrder?.totalPriceWithDiscount &&
            originalOrder?.totalAmountPaid.toString() ===
                originalOrder?.totalPriceWithDiscount.toString(),
        [originalOrder],
    )

    const validAmountToPay = useMemo(
        () =>
            (originalOrder?.totalPriceWithDiscount ?? 0) -
            (originalOrder?.totalAmountPaid ?? 0),
        [originalOrder],
    )
    const isAmountToPayValid = useMemo(
        () => (amount ?? 0) <= (originalOrder?.totalPriceWithDiscount ?? 0),
        [originalOrder],
    )

    const { paymentMethods } = useGetPaymentMethodsQuery(true)
    const paymentTypesOptions: PaymentTypeOption[] = useMemo(
        () =>
            paymentMethods
                ?.filter(method =>
                    isCompany
                        ? true
                        : method.type !== PaymentMethodName.Account,
                )
                .map(method => ({
                    label: method.name,
                    value: method.id.toString(),
                    payload: {
                        type: method.type,
                    },
                })),
        [paymentMethods, isCompany],
    )

    const { certificates } = useCertificatesQuery(counterpartyId, {
        enabled:
            !!counterpartyId && paymentType === PaymentMethodName.Certificate,
    })
    const certificatesOptions: CertificateOption[] = useMemo(
        () =>
            certificates
                ?.filter(cert => moment(cert.expiredAt).isAfter(moment()))
                ?.map(cert => ({
                    label: `${cert.name} (₽${cert.balance})`,
                    value: cert.id.toString(),
                    payload: {
                        balance: cert.balance.toString(),
                    },
                })),
        [certificates],
    )
    const currentCertificateBalance = useMemo(
        () => certificate?.payload?.balance ?? '0',
        [certificate],
    )

    const isButtonDisabled = useMemo(
        () =>
            !paymentType ||
            (paymentType === PaymentMethodName.Account && !amount) ||
            (paymentType === PaymentMethodName.Online && !amount) ||
            !isAmountToPayValid,
        [paymentTypeOption, amount],
    )

    const { payments, refetch } = useGetPaymentsQuery(orderId)
    const [prevPayments, setPrevPayments] = useState<typeof currentPayments>();
    const currentPayments = payments?.filter(p => p.orderId === orderId) ?? []

    useEffect(() => {
        if (!deepEqual(prevPayments, currentPayments)) {
            setPrevPayments(currentPayments);
            updateBookings(orderId, undefined, true).then();
        }
    }, [currentPayments]);

    const onChangePaymentType = (option: PaymentTypeOption) => {
        setPaymentTypeOption(option)
    }

    const onChangeCertificate = (option: CertificateOption) => {
        setCertificate(option)
    }

    const onChangeComment = (e: FormEvent) => {
        const newComment = (e.target as HTMLInputElement).value
        setComment(newComment)
    }

    const onEditAmount = (e: FormEvent) => {
        const newAmount = Math.abs(Number((e.target as HTMLInputElement).value))
        if (Number.isNaN(newAmount)) {
            return
        }
        setAmount(Math.min(validAmountToPay, newAmount))
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = (error?: string) => {
        dispatch(
            showNotification({
                message: error ?? 'Ошибка',
                type: 'error',
            }),
        )
    }

    const handleErrorBalanceAlert = (message?: string) => {
        if (message?.includes('The payment amount exceeds the order total.')) {
            showErrorAlert('Ошибка: Сумма платежа больше стоимости заказа.')
        } else {
            showErrorAlert()
        }
    }

    const makeCashPayment = async () => {
        try {
            await PaymentApi.createCashPayment({
                orderId: orderId!,
                amount: amount!,
                comment,
            })
            showSuccessAlert()
        } catch (e: any) {
            handleErrorBalanceAlert(e?.message)
        }
    }

    const makeCertificatePayment = async () => {
        try {
            if (!certificate?.value) {
                return showErrorAlert()
            }

            await PaymentApi.createCertificatePayment({
                orderId: orderId!,
                certificateId: Number(certificate.value),
                comment,
            })
            showSuccessAlert()
        } catch (e: any) {
            handleErrorBalanceAlert(e?.message)
        }
    }

    const redirectUrl = useMemo(
        () => `${window.location.origin}/invoice/${orderId}`,
        [orderId],
    )

    const onGoToInvoicePage = () => {
        const redirectUrl = `${window.location.origin}/invoice/${orderId}`
        window?.open(redirectUrl, '_blank')?.focus()
    }

    const makeOnlinePayment = async () => {
        try {
            await PaymentApi.createOnlinePayment({
                orderId: orderId!,
                amount: amount!,
                redirectUrl,
                comment,
            })

            dispatch(
                openModal({
                    isOpen: true,
                    content: (
                        <div>
                            <Text>
                                Отправьте эту ссылку заказчику, чтобы он смог
                                оплатить
                            </Text>
                            <div />
                            <Link to={redirectUrl}>{redirectUrl}</Link>
                        </div>
                    ),
                    config: {
                        title: 'Ссылка на оплату',
                        btns: [
                            {
                                onClick: () => {
                                    navigator.clipboard.writeText(redirectUrl)
                                },
                                title: 'Копировать',
                                active: true,
                            },
                            {
                                onClick: () => dispatch(closeModal()),
                                title: 'Закрыть',
                                active: false,
                            },
                        ],
                    },
                }),
            )
            showSuccessAlert()
        } catch (e: any) {
            handleErrorBalanceAlert(e?.message)
        }
    }

    const fillAccountPaymentDetails = async () => {
        dispatch(
            openModal({
                isOpen: true,
                isFullScreen: true,
                content:
                    originalOrder && counterparty ? (
                        <DetailsPaymentsModal
                            detailOrder={originalOrder}
                            counterparty={counterparty}
                            onPaymentProcess={onAccountPaymentProcess}
                        />
                    ) : (
                        <></>
                    ),
                config: {
                    title: 'Выставление счета',
                    btns: [
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Закрыть',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const confirmAccountPayment = async (payment: Payment) => {
        try {
            await PaymentApi.confirmAccountPayment(payment.id)
            showSuccessAlert()
            await updateBookings(orderId, undefined, true);
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }

    const openConfirmAccountPaymentModal = async (payment: Payment) => {
        dispatch(
            openModal({
                isOpen: true,
                content: <Text>Подтвердить платеж?</Text>,
                config: {
                    title: 'Подтверждение платежа',
                    btns: [
                        {
                            onClick: () => {
                                confirmAccountPayment(payment).then(async() => 
                                    await refetch(),
                                );
                                dispatch(closeModal())
                            },
                            title: 'Подтвердить платеж',
                            active: true,
                        },
                        {
                            onClick: async () => {
                                if (!orderId) {
                                    dispatch(closeModal())
                                    return
                                }

                                const documentToDownload = (
                                    await AttachmentsApi.getAllDocuments(
                                        orderId,
                                        payment.id,
                                    )
                                )?.[0]
                                if (!documentToDownload) {
                                    return
                                }
                                downloadFile(
                                    documentToDownload.uploadUrl,
                                    documentToDownload.fileName,
                                ).then()
                                dispatch(closeModal())
                            },
                            title: 'Скачать',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const onAccountPaymentProcess = async (accountDetails: AccountDetails) => {
        try {
            if (!accountDetails) {
                return
            }
            setIsLoading(true)

            const payment = await PaymentApi.createAccountPayment({
                orderId: orderId!,
                comment,
                amount: amount!,
                ...accountDetails,
            })

            if (orderId) {
                const documentToDownload = (
                    await AttachmentsApi.getAllDocuments(orderId, payment.id)
                )?.[0]

                if (documentToDownload) {
                    downloadFile(
                        documentToDownload.uploadUrl,
                        documentToDownload.fileName,
                    ).then()
                }
            }

            await updateBookings(orderId, undefined, true)
            await refetch()

            setPaymentTypeOption(undefined)
            setCertificate(undefined)
            setComment(undefined)
            setAmount(undefined)
            showSuccessAlert()
        } catch (e: any) {
            handleErrorBalanceAlert(e?.message)
        } finally {
            setIsLoading(false)
        }
    }

    const paymentHandlers: Record<
        PaymentMethodType,
        (() => Promise<void> | void) | null
    > = useMemo(
        () => ({
            [PaymentMethodName.Cash]: makeCashPayment,
            [PaymentMethodName.Certificate]: makeCertificatePayment,
            [PaymentMethodName.Online]: makeOnlinePayment,
            [PaymentMethodName.Account]: null,
        }),
        [makeCashPayment, makeCertificatePayment, makeOnlinePayment],
    )

    const onGoNext = async () => {
        try {
            if (!orderId || !paymentType) return
            setIsLoading(true)
            const paymentHandler =
                paymentHandlers?.[paymentType] ?? makeCashPayment
            if (paymentHandler) {
                await paymentHandler()
                await updateBookings(orderId, undefined, true)
                await refetch()

                setPaymentTypeOption(undefined)
                setCertificate(undefined)
                setComment(undefined)
                setAmount(undefined)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const paymentCallbacks = {
        [PaymentMethodName.Account]: openConfirmAccountPaymentModal,
    }

    return {
        isLoading,
        validAmountToPay,
        isAmountToPayValid,

        isPaid,
        isButtonDisabled,
        redirectUrl,

        amount,
        onEditAmount,

        certificate,
        currentCertificateBalance,
        certificatesOptions,
        onChangeCertificate,

        currentPayments,
        paymentTypeOption,
        paymentType,
        paymentTypesOptions,
        onChangePaymentType,
        paymentCallbacks,

        comment,
        onChangeComment,

        onGoNext,
        fillAccountPaymentDetails,
        onGoToInvoicePage,
    }
}
