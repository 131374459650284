import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    Counterparty,
    CounterpartyType,
    useCounterpartyAsyncAutocompleteOptions,
    useGetCounterpartyByIdQuery,
} from 'entities/counterparties/model'
import { useNumberMask } from 'shared/utils/useNumberMask'
import { selectBookingCounterparty } from '../slices'

const CounterpartyOptionName = {
    phone: 'phone',
    name: 'name',
    inn: 'inn',
    email: 'email',
    fio: 'contacts',
} as const

type CounterpartyOption = {
    [CounterpartyOptionName.phone]: string
    [CounterpartyOptionName.name]: string
    [CounterpartyOptionName.inn]: string
    [CounterpartyOptionName.email]: string
    [CounterpartyOptionName.fio]: string
}

const initValue = {
    [CounterpartyOptionName.phone]: '',
    [CounterpartyOptionName.name]: '',
    [CounterpartyOptionName.inn]: '',
    [CounterpartyOptionName.email]: '',
    [CounterpartyOptionName.fio]: '',
} as const

export type CounterpartyOptionNameType =
    (typeof CounterpartyOptionName)[keyof typeof CounterpartyOptionName]

const countryCode = '+7'
const phoneMask = `${countryCode}-000-000-00-00` as const

const buildCounterpartyOption = (counterpartyToBuild: Counterparty) => ({
    [CounterpartyOptionName.phone]: {
        value: counterpartyToBuild.id.toString(),
        label: counterpartyToBuild.phone,
        payload: counterpartyToBuild,
    },
    [CounterpartyOptionName.name]: {
        value: counterpartyToBuild.id.toString(),
        label:
            counterpartyToBuild.additionalData.documents?.organizationName ??
            '',
        payload: counterpartyToBuild,
    },
    [CounterpartyOptionName.fio]: {
        value: counterpartyToBuild.id.toString(),
        label: [
            counterpartyToBuild?.contact?.surname,
            counterpartyToBuild?.contact?.firstName,
            counterpartyToBuild?.contact?.patronymic,
        ].join(' '),
        payload: counterpartyToBuild,
    },
    [CounterpartyOptionName.inn]: {
        value: counterpartyToBuild.id.toString(),
        label: counterpartyToBuild.additionalData.documents?.inn ?? '',
        payload: counterpartyToBuild,
    },
    [CounterpartyOptionName.email]: {
        value: counterpartyToBuild.id.toString(),
        label: counterpartyToBuild.email ?? '',
        payload: counterpartyToBuild,
    },
})

const defaultCounterpartyObject = {
    [CounterpartyOptionName.phone]: null,
    [CounterpartyOptionName.name]: null,
    [CounterpartyOptionName.inn]: null,
    [CounterpartyOptionName.email]: null,
    [CounterpartyOptionName.fio]: null,
}

export const useCounterpartiesOptions = (isCompany?: boolean) => {
    const counterparty = useSelector(selectBookingCounterparty)
    const counterpartyId = counterparty?.id ?? null

    const { onInput, onKeyDown } = useNumberMask(phoneMask, countryCode)

    const [optionInputValue, setOptionInputValue] =
        useState<CounterpartyOption>(initValue)

    const {
        counterparty: currentCounterpartyObject,
        isFetching: isFetchingCounterpartyObject,
    } = useGetCounterpartyByIdQuery(counterpartyId ?? null)

    const onChangeCounterpartyOption = useCallback(
        (name: CounterpartyOptionNameType) => (value: string) => {
            setOptionInputValue(prev => ({
                ...prev,
                [name]: value,
            }))
        },
        [],
    )

    const searchString = useMemo(
        () =>
            optionInputValue.phone?.length
                ? optionInputValue.phone
                : optionInputValue.name?.length
                  ? optionInputValue.name
                  : optionInputValue.inn?.length
                    ? optionInputValue.inn
                    : undefined,
        [optionInputValue],
    )

    const {
        counterparties,
        fetchNextPage,
        isFetching: isFetchingCounterparties,
    } = useCounterpartyAsyncAutocompleteOptions({
        search_param: searchString,
        type_filter: isCompany
            ? CounterpartyType.Company
            : CounterpartyType.Person,
    })

    const counterpartiesOptions = useMemo(() => {
        if (!counterparties?.length) {
            return {
                [CounterpartyOptionName.phone]: [],
                [CounterpartyOptionName.name]: [],
                [CounterpartyOptionName.inn]: [],
                [CounterpartyOptionName.email]: [],
                [CounterpartyOptionName.fio]: [],
            }
        }

        const options = counterparties.map(buildCounterpartyOption)

        return {
            [CounterpartyOptionName.phone]: options.map(
                opt => opt[CounterpartyOptionName.phone],
            ),
            [CounterpartyOptionName.name]: options.map(
                opt => opt[CounterpartyOptionName.name],
            ),
            [CounterpartyOptionName.fio]: options.map(
                opt => opt[CounterpartyOptionName.fio],
            ),
            [CounterpartyOptionName.inn]: options.map(
                opt => opt[CounterpartyOptionName.inn],
            ),
            [CounterpartyOptionName.email]: options.map(
                opt => opt[CounterpartyOptionName.email],
            ),
        }
    }, [counterparties])

    const currentCounterpartyOption = useMemo(
        () =>
            currentCounterpartyObject
                ? buildCounterpartyOption(currentCounterpartyObject)
                : defaultCounterpartyObject,
        [currentCounterpartyObject],
    )

    const currentCounterparty = useMemo(
        () => ({
            email: currentCounterpartyObject?.email,
            name: [
                currentCounterpartyObject?.contact?.surname,
                currentCounterpartyObject?.contact?.firstName,
                currentCounterpartyObject?.contact?.patronymic,
            ].join(' '),
            contactEmail: currentCounterpartyObject?.contact?.email,
            contactPhone: currentCounterpartyObject?.contact?.phone,
            organizationName:
                currentCounterpartyObject?.additionalData?.documents
                    ?.organizationName,
            inn: currentCounterpartyObject?.additionalData?.documents?.inn,
        }),
        [currentCounterpartyObject],
    )

    const onChangeNumber = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('phone')

            if (value.length < (optionInputValue.phone?.length ?? 0)) {
                changeCallback(onKeyDown(value))
                return
            }
            changeCallback(onInput(value))
        },
        [optionInputValue, onChangeCounterpartyOption],
    )

    const onChangeName = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('name')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeInn = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('inn')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeEmail = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('email')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeContactName = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('name')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    return {
        optionInputValue,
        currentCounterpartyOption,
        counterpartiesOptions,
        currentCounterparty,
        fetchNextPage,
        isFetching: isFetchingCounterparties || isFetchingCounterpartyObject,
        onChangeNumber,
        onChangeName,
        onChangeInn,
        onChangeEmail,
        onChangeContactName,
    }
}
