import { AREASLIST, EMPLOYEESLIST, NOMENCLATURELIST } from "./routingConsts";

export const listPages = [
    {
        name: "Залы",
        link: AREASLIST,
    },
    {
        name: "Оборудование",
        link: NOMENCLATURELIST,
    },
    {
        name: "Сотрудники",
        link: EMPLOYEESLIST,
    }
]