import { Checkbox } from "shared/components/Checkbox"
import { SettingRow, SettingWrapper } from "./style"
import { useEffect, useState } from "react"
import { Loader } from "shared/components/Loader";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { getNotifications } from "../api/getNotifications";
import { IPropsNotificationsList, NotificationKeysList } from '../types'
import { patchNotifications } from "../api/patchNotifications";
import { Button } from "shared/components/Button";

const notificationList: NotificationKeysList = [
    {
        name: 'Уведомления',
        key: 'notifications',
    },
    {
        name: 'Акции',
        key: 'promotion',
    },
    {
        name: 'Отмена брони',
        key: 'booking_cansel',
    },
    {
        name: 'Освободилось время',
        key: 'time_freed_up',
    },
    {
        name: 'Новое бронирование',
        key: 'new_booking',
    },
    {
        name: 'Изменение бронирования',
        key: 'change_booking',
    },
]

export const SettingNotifications = () => {
    const [notificationMethods, setNotificationMethods] =
        useState<IPropsNotificationsList | null>(null)

    const { isLoading, data } = useQuery({
        queryKey: ['getNotifications'],
        queryFn: () => {
            return getNotifications()
        },
        placeholderData: keepPreviousData,
        retry: 3,
    })

    useEffect(() => {
        if (data && !notificationMethods) {
            setNotificationMethods(data.notifications)
        }
    }, [data]);
    
   const handleCheckboxChange = (key: keyof IPropsNotificationsList, index: number) => {
    setNotificationMethods((prev) => {
        if (!prev) return prev;
        
        const updated = { ...prev };

        // Проверяем, нажали ли на главный чекбокс "Уведомления"
        if (key === "notifications") {
            const newValue = !updated[key].notification_directions[index];

            // Обновляем все чекбоксы, относящиеся к Email или Telegram
            notificationList.forEach((item) => {
                // if (item.key !== "notifications") {
                    updated[item.key] = {
                        ...updated[item.key],
                        notification_directions: updated[item.key].notification_directions.map((_, i) => 
                            i === index ? newValue : updated[item.key].notification_directions[i]
                        ),
                    };
                // }
            });
        } else {
            // Обычное переключение для конкретного чекбокса
            const directions = [...updated[key].notification_directions];
            directions[index] = !directions[index];
            updated[key] = { ...updated[key], notification_directions: directions };
        }

        mutation.mutate(updated);
        return updated;
    });
};


    const mutation = useMutation({
        mutationFn: patchNotifications,
        onSuccess: () => {
            console.log("Уведомления обновлены!");
        },
        onError: (error) => {
            console.error("Ошибка при обновлении уведомлений:", error);
        }
    });

    if(isLoading) {
        return <Loader />
    }

    return (
        <SettingWrapper>
            <div className='title'>Настройки уведомлений</div>
            {!isLoading && data && notificationMethods && (
                <SettingRow>
                    {notificationList.map((item, index) => (
                        <div key={index} className="notificationBlock">
                            <div className="notificationBlock__title">{item.name}</div>
                            <div className="notificationBlock__checkbox-list">
                                <label>
                                    Email
                                    <Checkbox
                                        name="checkbox" 
                                        checked={notificationMethods[item.key as keyof IPropsNotificationsList]?.notification_directions[0]} 
                                        onChange={() => handleCheckboxChange(item.key as keyof IPropsNotificationsList, 0)} />
                                </label>
                                <label>
                                    Telegram
                                    <Checkbox 
                                        name="checkbox" 
                                        checked={notificationMethods[item.key as keyof IPropsNotificationsList]?.notification_directions[1]} 
                                        onChange={() => handleCheckboxChange(item.key as keyof IPropsNotificationsList, 1)} />
                                </label>
                            </div>
                        </div>
                    ))}
                    {data.telegram_link && <a className="btn-telegram" href={data.telegram_link} target="_blank" rel="noreferrer"><Button>Подключить Telegram</Button></a>}
                </SettingRow>
            )}
        </SettingWrapper>
    )
}
