import { axiosApi } from "shared/api/axios";
import { OrgsResponse } from "../types";

export const getOrgs = async (): Promise<OrgsResponse> => {
    try {
        const url = `${process.env.API_BACKEND}/v1/organizations/organization/`;
        const response = await axiosApi.get<OrgsResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};