import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'

export const confirmAccountPayment = async (payment_id: number) => {
    try {
        await axiosApi.post(
            `${PaymentEndpoint.ConfirmOnlinePayment}/${payment_id}`,
        )
        return true
    } catch (err: any) {
        throw new Error(
            `Ошибка создания платежа: ${err?.response?.data?.detail}`,
        )
    }
}
