import { axiosApi } from "shared/api/axios";

const AuthService = {
    login(login: string, password: string) {
        return axiosApi.post(`${process.env.API_OAUTH}/v1/auth/login`, { login, password });
    },
    auth() {
        return axiosApi.get(`${process.env.API_BACKEND}/v1/users/user/info/me`);
    },
    refreshToken() {
        return axiosApi.post(`${process.env.API_OAUTH}/v1/auth/refresh_token`);
    },
    logout() {
        return axiosApi.post(`${process.env.API_OAUTH}/v1/auth/logout`);
    },
    getPermissions(roleId: string) {
        return axiosApi.get(`${process.env.API_BACKEND}/v1/roles/role/${roleId}`);
    }
};

export default AuthService;